const APPLICATION_ROUTES = {
  AUTO_SIGN_IN: "/authorization/auto-sign-in",
  BIRTHDAY_PACKAGES: "/mymaor?birthday",
  DAILY_REBBE_VIDEO_DEDICATION_SECTION:
    "/projects/daily-rebbe-video/main?dedication",
  COMING_SOON: "/coming-soon",
  CHECKOUT: "/checkout",
  CHECKOUT_PREFILLED: "/checkout/:plan/:interval/:partnership",
  CONTACT: "/contact",
  OUR_TEAM: "/team",
  DAILY_REBBE_VIDEO_MAIN: "/projects/daily-rebbe-video/main",
  DAILY_REBBE_VIDEO_LIBRARY: "/library/daily-rebbe-video",
  DAILY_REBBE_VIDEO_LIBRARY_PROTECTED: "/library-protected/daily-rebbe-video",
  DONATION_REFUND_POLICY: "/donation-refund-policy",
  EMAIL_VERIFICATION_REQUEST: "/authorization/email-verification/request",
  EMAIL_VERIFY: "/authorization/verify-email",
  EMAIL_VERIFICATION_SUCCESS: "/authorization/email-verification/success",
  HOME: "/",
  HOME_PAGE: "/home",
  ILLUMINATE: "/illuminate",
  REFERRAL: "/share",
  ILLUMINATE_MAIN: "/projects/illuminate/main",
  ILLUMINATE_LIBRARY: "/library/illuminate",
  ILLUMINATE_LIBRARY_PROTECTED: "/library-protected/illuminate",
  LIBRARY: "/library",
  PLEDGE: "/pledge",
  PLEDGE_DONATION_SECTION: "pledge?donation",
  LIBRARY_PROECTED: "/library-protected",
  MY_MAOR_CHECKOUT: "/projects/my-maor/checkout",
  MY_MAOR_MAIN: "/mymaor",
  MY_MAOR_MAIN_2: "/mymaor2",
  MY_MAOR_MAIN_ID: "/:id",
  MY_MAOR_MAIN_OLD: "/projects/my-maor/main",
  MY_MAOR_LIBRARY: "/library/my-maor",
  MY_MAOR_LIBRARY_PROTECTED: "/library-protected/my-maor",
  MY_MAOR_ORDER: "/projects/my-maor/order",
  MY_MAOR_PACKAGES: "/projects/my-maor/packages",
  MY_MAOR_SUCCESS: "/projects/my-maor/success",
  NOT_FOUND: "/404",
  VIDEO_NOT_FOUND: "/video/unavailable",
  DONATE: "/donate",
  PARTNERS_DONATION: "/partners/donation",
  MY_MAOR_APP_DONATION: "/app",
  // PARTNERS_MAIN: "/partners/main",
  PARTNERS_MAIN_HOME: "/partners/main/home",
  PARTNERS_ORDER: "/partners/order",
  PARTNERS_ORDER_PREFILLED: "/partners/order/:plan/:interval",
  BIRTHDAY_ORDER: "/birthday/order",
  PARTNERS_PACKAGES: "/partners/packages",
  BIRTHDAY_PACKAGES_PAGE: "/birthday",
  PARTNERS_SUCCESS: "/partners/success",
  DONATION_SUCCESS: "/donation/success",
  PASSWORD_RECOVERY_REQUEST: "/authorization/password-recovery/request",
  PASSWORD_RECOVERY_SUCCESS: "/authorization/password-recovery/success",
  PASSWORD_RECOVERY_UPDATE: "/authorization/password-recovery/update",
  QUESTIONNAIRE_MAIN: "/authorization/questionnaire/main",
  QUESTIONNAIRE_SUCCESS: "/authorization/questionnaire/success",
  SIGNUP_SUCCESS: "/authorization/welcome",
  PRIVACY_POLICY: "/privacy-policy",
  PROFILE: "/profile",
  SPONSORS: "/sponsors",
  SIGN_UP_SHORT: "/sign-up",
  REFUND_POLICY: "/refund-policy",
  SIGN_IN: "/authorization/sign-in",
  SIGN_UP: "/authorization/sign-up",
  TERMS_OF_USE: "/terms-of-use",
  TEST: "/test",
  SUBSCRIPTION_CANCELED: "/subscription/canceled",
  PARTNERS_PAGE_TEST: "/partner-page-test",
  LEGACY_GIFT: "/legacy-gift",
  MY_ACCOUNT: {
    INDEX: "/my-account",
    MAILING_LIST: "/my-account/mailing-list",
    PAYMENT_METHOD: "/my-account/payment-method",
    PAYMENT_METHOD_ALL: "/my-account/payment-method/list",
    ADDRESSES: "/addresses",
  },
  SALE: "/sale",
  VERIFY_EMAIL: "/verify-email",
  PARTNER: "/partner",
  PARTNERS: "/partners",
  WRITINNG_COMPETITION: "/writing-competition",
  WRITINNG_COMPETITION_FORM: "/writing-competition-form",
  WRITING_COMPETITON_VIDEO_FORM: "/writing-competition-video-form",
  WRITINNG_COMPETITION_SUCCESS: "/writing-competition-success",
};

export default APPLICATION_ROUTES;
