import React from "react";
import cs from "classnames";
import classes from "./CompetitionLogInModal.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { APPLICATION_ROUTES } from "../../../../../constants";

const CompetitionLogInModal = () => {
  const history = useHistory();

  return (
    <div className={cs(classes.container)}>
      <h2 className={cs(classes.heading, classes.grandstanderFamily)}>Welcome to MyMaor!</h2>
      <div className={cs(classes.actions)}>
        <button
          onClick={() => {
            history.push(`${APPLICATION_ROUTES.SIGN_UP}?redirect=${APPLICATION_ROUTES.WRITINNG_COMPETITION}`);
          }}
          className={cs(classes.signUpButton)}
        >
          Sign up
        </button>
        <span className={cs(classes.orText)}>For a FREE account or</span>
        <button
          onClick={() => {
            history.push(`${APPLICATION_ROUTES.SIGN_IN}?redirect=${APPLICATION_ROUTES.WRITINNG_COMPETITION}`);
          }}
          className={cs(classes.logInButton)}
        >
          Log in
        </button>
      </div>
    </div>
  );
};

export default CompetitionLogInModal;
