import { API_ROUTES } from "../constants";
import API from "./_api";

const WritingCompetitionAPI = class extends API {
  get(userAuthenticated = false) {
    return this.request(
      userAuthenticated ? API_ROUTES.WRITING_COMPETITON.GET_DATA_AUTH : API_ROUTES.WRITING_COMPETITON.GET_DATA,
      {
        method: "GET",
      }
    );
  }

  getSubmission() {
    return this.request(API_ROUTES.WRITING_COMPETITON.GET_SUBMISSION, {
      method: "GET",
    });
  }

  submit(data) {
    return this.request(API_ROUTES.WRITING_COMPETITON.SUBMIT_CHARACTERS, {
      method: "POST",
      body: data,
    });
  }

  submitScript(data) {
    return this.request(API_ROUTES.WRITING_COMPETITON.SUBMIT_SCRIPT, {
      method: "POST",
      body: data,
    });
  }

  vote(submissionID = "") {
    return this.request(`${API_ROUTES.WRITING_COMPETITON.VOTE}/${submissionID}`, {
      method: "POST",
    });
  }
};

export default WritingCompetitionAPI;
