import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { COLORS, SIZES } from "./utilities";
import classes from "./RadioDotsCharacter.module.scss";

const RadioDotsCharacter = ({ color, horizontal, items, size, value, additionalClass, onChange, orangeBorderRadio = false }) => (
  <>
    <ul
      className={cs(classes.container, classes[additionalClass], classes[color], classes[size], {
        [classes.horizontal]: horizontal,
        [classes.vertical]: !horizontal,
      })}
    >

      {items.map((item) => {
        const checked = item.value === value;

        return (
          <li key={item.id} className={cs(classes.item, orangeBorderRadio && classes.orangeRadioTheme, { [classes.orangeRadioBorder]: orangeBorderRadio && checked })}>
            <input
              checked={checked}
              className={classes.input}
              id={item.id}
              type="radio"
              value={item.value}
              onChange={onChange}
            />
            <label className={classes.icon} htmlFor={item.id} />
            <label className={classes.label} htmlFor={item.id}>
              {item.label}
            </label>
          </li>
        );
      })}
    </ul>
    {/* {error && <div className={cs(classes.tooltip, classes.errorText)}>{error}</div>} */}
  </>
);

RadioDotsCharacter.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  horizontal: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  additionalClass: PropTypes.string,
  error: PropTypes.string,
  orangeBorderRadio: PropTypes.bool
};

RadioDotsCharacter.defaultProps = {
  color: COLORS.PRIMARY,
  horizontal: false,
  size: SIZES.MEDIUM,
  additionalClass: ""
};

export default RadioDotsCharacter;
