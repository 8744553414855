/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import cs from "classnames";
import classes from "./CharacterItem.module.scss";
import { StoreContext } from "../../../../../../../contexts";

export default function CharacterItem(characterData) {
  const { common } = useContext(StoreContext);
  const [showMore, setShowMore] = useState(false);
  const selectedCharacterData = common.get("currentCharacter");

  console.log(selectedCharacterData?.character_submission?.data?.setting, "characterDatacharacterData");

  const { name } = characterData?.character;

  // Custom key order
  const customOrder = [
    // "name",
    "age",
    "height",
    "wearing",
    "livingPlace", // Assuming "live" means "livingPlace"
    "favorite_mitzvah",
    "gender",
    "favorite_food",
    "favorite_activity",
    "favorite_yom_tov",
    "dislikes",
    "good_at",
    "fun_fact",
    "family",
    "additional_info",
    "happiness",
  ];

  const settingCustomKeyOrder = [
    "location",
    "place_type",
    "unique_aspects",
    "view",
    "weather",
    "where_in_world",
    "transportation",
    "rules_and_traditions",
    "additional_info",
  ];

  const characterInformatino = [];
  const settingInformation = [];

  customOrder.forEach((key) => {
    if (characterData?.character[key] && characterData?.character[key] != "") {
      characterInformatino.push({
        label: key,
        value: characterData?.character[key] || "",
      });
    }
  });

  settingCustomKeyOrder.forEach((key) => {
    if (
      selectedCharacterData?.character_submission?.data?.setting[key] &&
      selectedCharacterData?.character_submission?.data?.setting[key] != ""
    ) {
      settingInformation.push({
        label: key,
        value: selectedCharacterData?.character_submission?.data?.setting[key] || "",
      });
    }
  });

  console.log(settingInformation, "settingCustomKeyOrder");

  return (
    <div className={cs(classes.componentContainer)}>
      <div className={cs(classes.nameTag, classes.grandstanderFamily)}>{name || ""}</div>
      <div className={cs(classes.detailsContainer)}>
        {characterInformatino.slice(0, showMore ? characterInformatino.length : 5).map((character, index) => (
          <div className={cs(classes.itemKey)} key={index}>
            <span className={cs(classes.fontMedium)}>
              {character.label.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())}:
            </span>{" "}
            <span className={cs(classes.desc)}>{character.value}</span>
          </div>
        ))}
        {showMore && <div className={cs(classes.placeTag, classes.grandstanderFamily)}>Place</div>}
        {showMore &&
          settingInformation?.map((cur, index) => (
            <div className={cs(classes.itemKey)} key={index}>
              <span className={cs(classes.fontMedium)}>
                {cur.label.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())}:
              </span>{" "}
              <span className={cs(classes.desc)}>{cur.value}</span>
            </div>
          ))}
      </div>
      <button className={cs(classes.detailsButton, classes.grandstanderFamily)} onClick={() => setShowMore(!showMore)}>
        {showMore ? "See Less" : "See More"}
        {showMore ? <ChevronUp className="ml-2 h-4 w-4" /> : <ChevronDown className="ml-2 h-4 w-4" />}
      </button>
    </div>
  );
}
