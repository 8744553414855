import React, { useContext, useEffect } from "react";
import cs from "classnames";
import classes from "./SettingsSection.module.scss";
import PlainInputCharacter from "../../../../atoms/PlainInputCharacter";
import RadioDotsCharacter from "../../../../atoms/RadioDotsCharacter";
import questionIcon from "../../../../../assets/images/question.png";
import TextAreaCharacter from "../../../../atoms/TextAreaCharacter";
import { observer } from "mobx-react-lite";
import { CHARACTER_SUBMISSION_KEY } from "../../WritingCompetitionForm";
import { StoreContext } from "../../../../../contexts";
import Tooltip from "../../../../molecules/Tooltip";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const SettingsSection = observer(
  ({
    placeName,
    rulesAndTradition,
    whereInWorld,
    weatherLike,
    unique,
    moreAbout,
    getAroundRadio,
    getAroundInput,
    getPlaceInput,
    getPlaceRadio,
    getViewInput,
    getViewRadio,
    getStoryTimeRadio,
  }) => {
    const placeNameValue = placeName.value || "_____";
    const isPlaceNameEmpty = !placeName.value;

    const { common } = useContext(StoreContext);

    const submissionData = common.get(CHARACTER_SUBMISSION_KEY);
    const setting = submissionData.setting ?? null;

    useEffect(() => {
      if (setting) {
        let settingInputMap = {
          location: placeName,
          weather: weatherLike,
          transportation: setting.transportation
            ? getAroundRadio.options.some((option) => option.value === setting.transportation.toString())
              ? getAroundRadio
              : getAroundInput
            : getAroundInput,
          rules_and_traditions: rulesAndTradition,
          where_in_world: whereInWorld,
          unique_aspects: unique,
          additional_info: moreAbout,
          place_type: setting.place_type
            ? getPlaceRadio.options.some((option) => option.value === setting.place_type.toString())
              ? getPlaceRadio
              : getPlaceInput
            : getPlaceInput,
          view: setting.view
            ? getViewRadio.options.some((option) => option.value === setting.view.toString())
              ? getViewRadio
              : getViewInput
            : getViewInput,
          storyTime: getStoryTimeRadio,
        };

        Object.keys(setting).forEach((key) => {
          if (setting.transportation) {
            if (
              key === "transportation" &&
              !getAroundRadio.options.some((option) => option.value === setting.transportation.toString())
            ) {
              // If transportation doesn't match, set "Other" in getAroundRadio
              getAroundRadio.onChange({
                target: { value: "Other" },
                touched: false,
              });
            }
          }

          if (setting.place_type) {
            if (
              key === "place_type" &&
              !getPlaceRadio.options.some((option) => option.value === setting.place_type.toString())
            ) {
              // Fallback for 'place_type' if it's not found in the options
              getPlaceRadio.onChange({
                target: { value: "Other" },
                touched: false,
              });
            }
          }

          if (setting.view) {
            if (key === "view" && !getViewRadio.options.some((option) => option.value === setting.view.toString())) {
              // Fallback for 'view' if it's not found in the options
              getViewRadio.onChange({
                target: { value: "Other" },
                touched: false,
              });
            }
          }

          if (settingInputMap[key]) {
            settingInputMap[key].onChange({
              target: { value: setting[key] },
              touched: false,
            });
          }
        });
      }
    }, []);

    return (
      <div className={cs(classes.settingContainer)}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 1019: 1, 1020: 2 }}>
          <Masonry columnsCount={2} gutter="30px">
            <div className={cs(classes.characterFieldContainer, placeName.error && classes.errorbg)}>
              <label htmlFor="place-name-input">
                Name of place: <span className={cs(classes.red)}> * </span>
              </label>
              <PlainInputCharacter
                error={placeName.error}
                id="place-name-input"
                label={placeName.label}
                value={placeName.value}
                onChange={placeName.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            <div
              className={cs(
                classes.characterFieldContainer,
                getViewInput.error && classes.errorbg,
                isPlaceNameEmpty && classes.deactive
              )}
            >
              <label className={cs(classes.hrGrey)} htmlFor="view-input">
                What is the view in <span className={cs(classes.placeNameText)}>&nbsp;{placeNameValue} </span>?{" "}
                <span className={cs(classes.red)}> * </span>
              </label>
              <RadioDotsCharacter
                vertical
                items={getViewRadio.options}
                value={getViewRadio.value}
                onChange={getViewRadio.onChange}
                error={getViewRadio.error}
                orangeBorderRadio={true}
              />
              {getViewRadio.value === "Other" && (
                <div className={cs(classes.radioInput)}>
                  <PlainInputCharacter
                    error={getViewInput.error}
                    id="view-input"
                    label={getViewInput.label}
                    value={getViewInput.value}
                    onChange={getViewInput.onChange}
                    placeHolder="Short Answer text"
                  />
                </div>
              )}
            </div>

            <div
              className={cs(
                classes.characterFieldContainer,
                getPlaceInput.error && classes.errorbg,
                isPlaceNameEmpty && classes.deactive
              )}
            >
              <label className={cs(classes.hrGrey)} htmlFor="place-type-input">
                <span className={cs(classes.placeNameText)}> {placeNameValue} &nbsp;</span> is a:{" "}
                <span className={cs(classes.red)}> * </span>
              </label>
              <RadioDotsCharacter
                vertical
                items={getPlaceRadio.options}
                value={getPlaceRadio.value}
                onChange={getPlaceRadio.onChange}
                error={getPlaceRadio.error}
                orangeBorderRadio={true}
              />
              {getPlaceRadio.value === "Other" && (
                <div className={cs(classes.radioInput)}>
                  <PlainInputCharacter
                    error={getPlaceInput.error}
                    id="place-type-input"
                    label={getPlaceInput.label}
                    value={getPlaceInput.value}
                    onChange={getPlaceInput.onChange}
                    placeHolder="Short Answer text"
                  />
                </div>
              )}
            </div>

            <div className={cs(classes.characterFieldContainer, isPlaceNameEmpty && classes.deactive)}>
              <label className={cs(classes.hrGrey)} htmlFor="transport-input">
                How do the people of <span className={cs(classes.placeNameText)}>&nbsp; {placeNameValue} &nbsp;</span>{" "}
                get around?
              </label>
              <RadioDotsCharacter
                vertical
                items={getAroundRadio.options}
                value={getAroundRadio.value}
                onChange={getAroundRadio.onChange}
                error={getAroundRadio.error}
                orangeBorderRadio={true}
              />
              {getAroundRadio.value === "Other" && (
                <div className={cs(classes.radioInput)}>
                  <PlainInputCharacter
                    error={getAroundInput.error}
                    id="transport-input"
                    label={getAroundInput.label}
                    value={getAroundInput.value}
                    onChange={getAroundInput.onChange}
                    placeHolder="Short Answer text"
                  />
                </div>
              )}
            </div>

            <div
              className={cs(
                classes.characterFieldContainer,
                whereInWorld.error && classes.errorbg,
                isPlaceNameEmpty && classes.deactive
              )}
            >
              <label htmlFor="location-input">
                Where in the world is <span className={cs(classes.placeNameText)}>&nbsp; {placeNameValue} </span>?{" "}
                <span className={cs(classes.red)}> * </span>
                <div style={{ marginTop: "6px" }} className={cs(classes.tooltip, "ml-auto as-start c-grey-dark")}>
                  <Tooltip
                    message={`Example: "${placeNameValue} is located in the mountains of Switzerland."`}
                    color="yellow"
                  >
                    <img src={questionIcon} alt="questionIcon" className={cs(classes.questionIcon)} />
                  </Tooltip>
                </div>
              </label>
              <PlainInputCharacter
                error={whereInWorld.error}
                id="location-input"
                label={whereInWorld.label}
                value={whereInWorld.value}
                onChange={whereInWorld.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            <div className={cs(classes.characterFieldContainer, isPlaceNameEmpty && classes.deactive)}>
              <label htmlFor="rules-input">
                Are there any rules or traditions in{" "}
                <span className={cs(classes.placeNameText)}>&nbsp; {placeNameValue} </span>?
                <Tooltip
                  message={`Example: "Yes, ${placeNameValue} celebrates 'Cholent Festival' every winter, where families compete to make the best cholent."`}
                  color="yellow"
                >
                  <img src={questionIcon} alt="questionIcon" className={cs(classes.questionIcon)} />
                </Tooltip>
              </label>
              <PlainInputCharacter
                error={rulesAndTradition.error}
                id="rules-input"
                label={rulesAndTradition.label}
                value={rulesAndTradition.value}
                onChange={rulesAndTradition.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            <div
              className={cs(
                classes.characterFieldContainer,
                weatherLike.error && classes.errorbg,
                isPlaceNameEmpty && classes.deactive
              )}
            >
              <label htmlFor="weather-input">
                What is the weather like in <span className={cs(classes.placeNameText)}>&nbsp; {placeNameValue} </span>?{" "}
                <span className={cs(classes.red)}> * </span>
              </label>
              <PlainInputCharacter
                error={weatherLike.error}
                id="weather-input"
                label={weatherLike.label}
                value={weatherLike.value}
                onChange={weatherLike.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            <div className={cs(classes.characterFieldContainer, isPlaceNameEmpty && classes.deactive)}>
              <label htmlFor="special-input">
                What makes <span className={cs(classes.placeNameText)}> &nbsp;{placeNameValue}&nbsp; </span> special?
                <Tooltip
                  message={`Example: "${placeNameValue} is a place where everyone works together to bring Moshiach."`}
                  color="yellow"
                >
                  <img src={questionIcon} alt="questionIcon" className={cs(classes.questionIcon)} />
                </Tooltip>
              </label>
              <PlainInputCharacter
                error={unique.error}
                id="special-input"
                label={unique.label}
                value={unique.value}
                onChange={unique.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            <div
              className={cs(
                classes.characterFieldContainer,
                getStoryTimeRadio.error && classes.errorbg,
                isPlaceNameEmpty && classes.deactive
              )}
            >
              <label className={cs(classes.hrGrey)} htmlFor="story-time-input">
                When does the story of <span className={cs(classes.placeNameText)}> &nbsp;{placeNameValue}&nbsp;</span>{" "}
                take place? <span className={cs(classes.red)}> * </span>
              </label>
              <RadioDotsCharacter
                vertical
                items={getStoryTimeRadio.options}
                value={getStoryTimeRadio.value}
                onChange={getStoryTimeRadio.onChange}
                error={getStoryTimeRadio.error}
                orangeBorderRadio={true}
              />
            </div>

            <div className={cs(classes.characterFieldContainer, isPlaceNameEmpty && classes.deactive)}>
              <label htmlFor="details-input">
                Tell us more about <span className={cs(classes.placeNameText)}> &nbsp;{placeNameValue} </span>:
                {/* <img src={questionIcon} alt="questionIcon" className={cs(classes.questionIcon)} /> */}
              </label>
              <TextAreaCharacter
                error={moreAbout.error}
                id="details-input"
                label={moreAbout.label}
                value={moreAbout.value}
                onChange={moreAbout.onChange}
                placeHolder="Long Answer text"
              />
            </div>
          </Masonry>
        </ResponsiveMasonry>
      </div>
    );
  }
);

export default SettingsSection;
