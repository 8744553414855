/* eslint-disable no-unused-vars */

import React, { useContext } from "react";
import cs from "classnames";
import classes from "./Characters.module.scss";
import AddCharacter from "../../../../../assets/images/add-charcter.webp";
import EditCharacter from "../../../../../assets/images/character-edit.png";
import { observer } from "mobx-react-lite";
import Modal from "../../../../organisms/Modal";
import CharacterForm from "./components/CharacterForm";
import { useModal } from "../../../../../hooks";
import Icon, { NAMES } from "../../../../atoms/Icon";
import { StoreContext } from "../../../../../contexts";
import { CHARACTER_SUBMISSION_KEY } from "../../WritingCompetitionForm";
import useForm from "../../useForm";

const Characters = observer(() => {
  const characterFormModal = useModal();
  const { common } = useContext(StoreContext);
  const { submitStory } = useForm();

  const submissionData = common.get(CHARACTER_SUBMISSION_KEY);
  const characters = submissionData.characters ?? [];

  const MAX_CHARACTERS = 5;

  const addCharacter = () => {
    // const newCharacterIndex = submissionData.characters.length + 1;
    // console.log('index', newCharacterIndex);

    // common.set(CHARACTER_SUBMISSION_KEY, {
    //   ...submissionData,
    //   characters: [
    //     ...submissionData.characters,
    //     {
    //       ...defaultCharacter(),
    //       name: `Character #${newCharacterIndex}`,
    //     },
    //   ],
    // });

    common.set("currentCharacterIndex", -1);
  };

  const editCharacter = (index) => {
    common.set("currentCharacterIndex", index);
  };

  const removeCharacter = (index) => {
    const newCharacters = [...characters];
    newCharacters.splice(index, 1);
    common.set(CHARACTER_SUBMISSION_KEY, {
      ...submissionData,
      characters: newCharacters,
    });

    submitStory();
  };

  return (
    <div className={cs(classes.container)}>
      <div className={cs(classes.grid)}>
        {characters.map((character, index) => (
          <div
            key={index}
            className={cs(classes.card)}
            onClick={() => {
              characterFormModal.onOpen();
              editCharacter(index);
            }}
          >
            <div className={cs(classes.characterSquare)}>
              <img src={EditCharacter} alt="EditCharacter" className={cs(classes.icon)} />
              <p
                className={cs(
                  character ? (character.status == "pending" || !character.status ? classes.red : classes.green) : ""
                )}
              >
                {character ? (character.status == "pending" || !character.status ? "Incomplete" : "Completed") : ``}
              </p>
            </div>
            <span className={cs(classes.characterLabel, classes.grandstanderFamily)}>
              {character.name ? character.name : `Character #${index + 1}`}
            </span>

            {index > 2 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removeCharacter(index);
                }}
                className={cs(classes.deleteModal)}
              >
                X
              </button>
            )}
          </div>
        ))}

        {characters.length < MAX_CHARACTERS && (
          <div
            className={cs(classes.card)}
            onClick={() => {
              if (characters.length < MAX_CHARACTERS) {
                addCharacter();
                characterFormModal.onOpen();
              }
            }}
          >
            <div className={cs(classes.characterSquare, classes.addCharacter)}>
              <img src={AddCharacter} alt="AddCharacter" className={cs(classes.icon)} />
            </div>
            <span className={cs(classes.addCharacterLabel, classes.grandstanderFamily)}>Add Character</span>
          </div>
        )}
      </div>

      <Modal opened={characterFormModal.isOpened}>
        <div className={cs(classes.addCharacterModalConatiner)}>
          <div className={cs(classes.addCharacterModalCard)}>
            <CharacterForm onClose={characterFormModal.onClose} />
            <div
              className={cs(classes.iconCross)}
              onClick={() => {
                characterFormModal.onClose();
              }}
            >
              <Icon name={NAMES.X} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default Characters;
