import React, { useContext } from "react";
import cs from "classnames";
import classes from "./ToichenOptions.module.scss";
import { StoreContext } from "../../../../../contexts";
import { WRITING_COMPETITON_DATA_KEY } from "../../WritingCompetition";

const ToichenOptions = () => {
  const { common } = useContext(StoreContext);

  const writingCompetitionData = common.get(WRITING_COMPETITON_DATA_KEY);

  return (
    <div className={cs(classes.componentContainer, classes.grandstanderFamily)}>
      <h2 className={cs(classes.header)}>TOICHEN OPTIONS:</h2>
      <div className={cs(classes.optionsContainer)}>
        {writingCompetitionData?.details?.toichen_buttons.map((option) => (
          <button
            onClick={() => {
              window.open(option.link, "_blank");
            }}
            key={option.text}
            className={cs(classes.button)}
          >
            {option.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ToichenOptions;
