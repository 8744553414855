import React, { useContext } from "react";
import cs from "classnames";
import classes from "./NewProgressBar.module.scss";
import LightActive from "../../../../../assets/images/light-active.png";
import LightInActive from "../../../../../assets/images/light-inactive.png";
import ScriptActive from "../../../../../assets/images/srcipt-active.png";
import ScriptInActive from "../../../../../assets/images/script-inactive.png";
import VoteActive from "../../../../../assets/images/vote-active.png";
import VoteInActive from "../../../../../assets/images/vote-inactive.png";
import AwardActive from "../../../../../assets/images/award-active.png";
import AwardInActive from "../../../../../assets/images/award-inactive.png";
import { StoreContext } from "../../../../../contexts";
import { stageColors, WRITING_COMPETITON_DATA_KEY } from "../../WritingCompetition";
import Loader from "../../../../atoms/Loader";
import { ACTIONS } from "../../../../../store";

const stages = [
  {
    name: "Character Building",
    activeIcon: LightActive,
    inActiveIcon: LightInActive,
  },
  {
    name: "Script Submission",
    activeIcon: ScriptActive,
    inActiveIcon: ScriptInActive,
  },
  { name: "Voting", activeIcon: VoteActive, inActiveIcon: VoteInActive },
  {
    name: "Award Show",
    activeIcon: AwardActive,
    inActiveIcon: AwardInActive,
  },
];

const NewProgressBar = () => {
  const { common, writingcompetition } = useContext(StoreContext);
  const getAction = ACTIONS.WRITING_COMPETITON.GET;

  const writingCompetitionData = common.get(WRITING_COMPETITON_DATA_KEY);
  const stageData = writingCompetitionData?.stages;

  if (!stageData || Object.keys(stageData).length === 0) {
    return writingcompetition[`isLoading${getAction}`] ? <Loader /> : null;
  }

  return (
    <div className={classes.progressContainer}>
      {stages.map((stage, index) => {
        const stageInfo = stageData[stage.name];
        if (!stageInfo) return null; // Skip if stageInfo is undefined

        const status = () => {
          if (stageInfo.is_completed) return "completed";
          if (stageInfo.is_active) return "active";
          return "upcoming";
        };

        const stageColor = stageColors[stage.name];

        return (
          <div key={index} className={classes.stageWrapper}>
            <div className={cs(classes.stageCircle)}>
              <img
                src={status() === "active" || status() === "completed" ? stage.activeIcon : stage.inActiveIcon}
                alt={stage.name}
                className={cs(classes.progressIcon)}
              />
            </div>
            {stage.name == "Award Show" &&
            writingCompetitionData.details.stage === "Award Show" &&
            writingCompetitionData?.details?.is_winner_selected ? (
              <></>
            ) : (
              <>
                {(status() === "active" ||
                  (stage.name == "Award Show" && writingCompetitionData.details.stage === "Award Show")) &&
                  stageInfo.days_left > 0 && (
                    <span className={classes.stageDaysLeft} style={{ color: stageColor }}>
                      {stageInfo.days_left} {stageInfo.days_left == 1 ? "Day" : "Days"} Left
                    </span>
                  )}
                {(status() === "active" ||
                  (stage.name == "Award Show" && writingCompetitionData.details.stage === "Award Show")) &&
                  stageInfo.days_left == 0 && (
                    <span className={classes.stageDaysLeft} style={{ color: stageColor }}>
                      {stage.name == "Award Show" ? "Coming soon!" : "Last Day"}
                    </span>
                  )}
              </>
            )}
          </div>
        );
      })}

      {/* Dots */}
      <div className={classes.dotsContainer}>
        {stages.map((stage, index) => {
          if (index < stages.length - 1) {
            const stageInfo = stageData[stage.name];
            if (!stageInfo) return null;

            const stageColor = stageColors[stage.name];
            const completedDots = Math.floor((stageInfo.progress_percentage / 100) * 6);

            return (
              <div key={index} className={classes.dotsGroup}>
                {[...Array(6)].map((_, dotIndex) => {
                  const isFirstDot = dotIndex === 0 && stageInfo.progress_percentage > 0;

                  return (
                    <div
                      key={dotIndex}
                      className={cs(
                        classes.dot,
                        stageInfo.is_completed || isFirstDot || dotIndex < completedDots
                          ? classes.dotActive
                          : classes.dotDefault
                      )}
                      style={{
                        backgroundColor:
                          stageInfo.is_completed || isFirstDot || dotIndex < completedDots ? stageColor : "#d1d5db", // Default gray for inactive dots
                      }}
                    />
                  );
                })}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default NewProgressBar;
