import React from "react";
import cs from "classnames";
import classes from "./WritingCompetitionSuccess.module.scss";
import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";
import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import WritingCompetitionLayout from "../../templates/WritingCompetitionLayout";
import Icon, { NAMES } from "../../atoms/Icon";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const WritingCompetitionSuccess = () => {
  const history = useHistory();

  return (
    <>
      <PageTitle value={PAGE_TITLES.WRITINNG_COMPETITION_SUCCESS} />
      <Layout logotype="maor">
        <WritingCompetitionLayout>
          <>
            <div className={cs(classes.writingCompetitionSuccess, classes.grandstanderFamily)}>
              <div className={cs(classes.successTopText)}>
                <h2 className={cs(classes.grandstanderFamily)}>Yayyy!</h2>
                <h1>Your Entry has been submitted!</h1>
              </div>
              <div className={cs(classes.successBottomText)}>
                <h4>We&apos;re checking what you sent now.</h4>
                <p>Don&apos;t forget to come back soon to the MyMaor Competition page to see your amazing entry! 😊</p>
              </div>
              <div
                className={cs(classes.crossButton)}
                onClick={() => history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION)}
              >
                <Icon name={NAMES.Cross} />
              </div>
            </div>
          </>
        </WritingCompetitionLayout>
      </Layout>
    </>
  );
};

export default WritingCompetitionSuccess;
