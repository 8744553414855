import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

// import Avatar from "../../atoms/Avatar";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Loader from "../../atoms/Loader";
import Tooltip, { COLORS as TOOLTIP_COLORS, POSITIONS as TOOLTIP_POSITIONS } from "../../molecules/Tooltip";
import Popover, { POSITIONS as POPOVER_POSITIONS, usePopover } from "../../organisms/Popover";
import { APPLICATION_ROUTES, COOKIES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import { scrollToTop } from "../../../utilities/helpers";
import classes from "./ProfileMenu.module.scss";
import LetterPicture from "../../atoms/LetterPIcture";
import Partnership from "../Partnership";
import Cookies from "../../../utilities/cookies";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { WRITING_COMPETITON_DATA_KEY } from "../../pages/WritingCompetition/WritingCompetition";
// import { scroller } from "react-scroll";

const ProfileMenu = observer(({ id }) => {
  const { authorization, common } = useContext(StoreContext);
  const onSignOut = () => {
    Cookies.delete({ name: COOKIES.AUTO_SIGNIN });
    Cookies.delete({ name: COOKIES.CART_ID });
    common.set(WRITING_COMPETITON_DATA_KEY, null);

    authorization.signOut();
  };

  const dropdown = usePopover({
    position: isMobile ? POPOVER_POSITIONS.RIGHT_TOP_MOBILE : POPOVER_POSITIONS.RIGHT_BOTTOM_OUT,
    selector: `#${id}`,
  });

  const history = useHistory();
  // const onNavigateToProfile = useCallback(() => {
  //   history.push(APPLICATION_ROUTES.PROFILE);
  //   scrollToTop();
  // }, []);
  // const onNavigateToBirthday = useCallback(() => {
  //   history.push(APPLICATION_ROUTES.MY_MAOR_MAIN + "?birthday");
  //   // scrollToTop();
  //   scroller.scrollTo("packages", { duration: 500, smooth: true, offset: -24 });
  // });

  const onNavigateToMyAccount = () => {
    history.push(APPLICATION_ROUTES.MY_ACCOUNT.MAILING_LIST);
    scrollToTop();
  };

  const onNavigateToPaymentMethod = () => {
    history.push(APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD_ALL);
    scrollToTop();
  };

  const onNavigateToAddresses = () => {
    history.push(APPLICATION_ROUTES.MY_ACCOUNT.ADDRESSES);
    scrollToTop();
  };

  const onNavigateToSignIn = useCallback(() => {
    history.push(APPLICATION_ROUTES.SIGN_IN);
    scrollToTop();
  }, []);

  const { t } = useTranslation();
  const MESSAGE = "";

  if (authorization.isLoadingSignIn || authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`]) {
    return (
      <div className={classes.container}>
        <Loader />
      </div>
    );
  }

  let memberIcon = "";
  if (authorization.isAuthenticated && authorization.isUserMember && authorization.membership) {
    memberIcon = classes[authorization.membership.memberClass];
  }

  console.log(authorization?.isUserMember, "membership");

  return (
    <div className={classes.container}>
      {authorization.isAuthenticated ? (
        <>
          <div className={classes.menu} id={id} onClick={dropdown.open}>
            {isMobile ? (
              <MobileView>
                {authorization.isUserMember && authorization.membership ? (
                  <div className={cs(classes.familyWrapper, classes.paidMember, memberIcon)}>
                    <div className={classes.avatar}>
                      <span>{authorization.lastName.charAt(0).toUpperCase()}</span>
                    </div>
                  </div>
                ) : (
                  <div className={classes.familyWrapper}>
                    <div className={classes.avatar}>
                      <LetterPicture color="#FF8A34" name={authorization.lastName} />
                    </div>
                  </div>
                )}
              </MobileView>
            ) : (
              <BrowserView>
                <div className={classes.familyWrapper}>
                  {/* {authorization.isAuthenticated && (
                    <div className={classes.avatar}>
                      <LetterPicture
                        color="#FF8A34"
                        name={authorization.lastName}
                      />
                    </div>
                  )} */}
                  <div className={cs(classes.familyWelcomeText, classes.marginBottom)}>
                    <p className={classes.textProfileMenu}>
                      {t("components.molecules.profile_menu.texts.2")} <br />
                      <strong>
                        {authorization.lastName.charAt(0).toUpperCase() + authorization.lastName.slice(1)}
                      </strong>{" "}
                      {t("layout.family")}
                    </p>
                    <span className={cs(classes.accountText)}>
                      Account <Icon name="arrow-dropdown" size="xs" />{" "}
                    </span>
                  </div>
                  {authorization.isUserMember && authorization.membership ? (
                    <>
                      {authorization?.membership?.memberClass == "partner" ? (
                        <div className={cs(classes.familyWrapper, classes.paidMember, memberIcon)}>
                          <div className={classes.avatar}>
                            <span>{authorization.lastName.charAt(0).toUpperCase()}</span>
                          </div>
                        </div>
                      ) : (
                        <div className={classes.membershipBadge}>
                          <img
                            src={authorization.membership.Icon}
                            alt={t(authorization.membership.ILLUSTRATION_NAME)}
                          />
                        </div>
                      )}
                    </>
                  ) : null}
                  {/* {!authorization.user.has_purchased_partnership ? (
                    <div className={classes.becomePartner}>
                      <Link
                        to={APPLICATION_ROUTES.PARTNERS_PACKAGES}
                        className="c-orange"
                      >
                        Become <br /> Partner
                      </Link>
                    </div>
                  ) : null} */}
                </div>
              </BrowserView>
            )}

            {MESSAGE && (
              <div className={classes.information}>
                <Tooltip
                  color={TOOLTIP_COLORS.WHITE}
                  message={MESSAGE}
                  position={TOOLTIP_POSITIONS.RIGHT_TOP_OUT}
                  size="m"
                >
                  <Icon name="information" size="s" />
                </Tooltip>
              </div>
            )}
          </div>
          <Popover disablePortalSelector={true} close={dropdown.close} style={dropdown.style}>
            <ul className={cs(classes.dropdown, "bxsh-hard")}>
              {/* <li className={classes.item}>
                <div className={classes.greetings}>
                  {t("components.molecules.profile_menu.texts.1", {
                    value: authorization.lastName,
                  })}
                </div>
              </li> */}
              <li className={classes.subscription}>
                <Partnership dropdown={dropdown} />
              </li>
              {authorization.isAuthenticated && (
                <>
                  {/* <li className={classes.item}>
                    <button
                      className={classes.button}
                      onClick={onNavigateToMyAccount}
                    >
                      <span className="mr-03 c-orange">
                        <Icon name="letter" />
                      </span>
                      <span>
                        {t("components.molecules.profile_menu.labels.8")}
                      </span>
                    </button>
                  </li> */}

                  <li className={classes.item}>
                    <button className={classes.button} onClick={onNavigateToPaymentMethod}>
                      <span className="mr-03 c-orange">
                        <Icon name="credit-card" />
                      </span>
                      <span>{t("components.molecules.profile_menu.labels.7")}</span>
                    </button>
                  </li>

                  <li className={classes.item}>
                    <button className={classes.button} onClick={onNavigateToMyAccount}>
                      <span className="mr-03 c-orange">
                        <Icon name="letter" />
                      </span>
                      <span>{t("components.molecules.profile_menu.labels.6")}</span>
                    </button>
                  </li>

                  <li className={classes.item}>
                    <button className={classes.button} onClick={onNavigateToAddresses}>
                      <span className="mr-03 c-orange">
                        <Icon name="credit-card" />
                      </span>
                      <span>{t("components.molecules.profile_menu.labels.8")}</span>
                    </button>
                  </li>
                </>
              )}
              {/* <li className={classes.item}>
                
                <button
                  className={classes.button}
                  onClick={onNavigateToBirthday}
                >
                  <span className="mr-03 c-orange">
                    <Icon name="cake" />
                  </span>
                  <span>{t("components.molecules.profile_menu.labels.5")}</span>
                </button>
              </li> */}
              {/* <li className={classes.item}>
                <button className={classes.button} onClick={onNavigateToProfile}>
                  <span className="mr-03 c-orange">
                    <Icon name="user" />
                  </span>
                  <span>{t("components.molecules.profile_menu.labels.4")}</span>
                </button>
              </li> */}
              <li className={classes.item}>
                <button className={classes.button} onClick={onSignOut}>
                  <span className="mr-03 c-red">
                    <Icon name="logout" />
                  </span>
                  <span>{t("components.molecules.profile_menu.labels.3")}</span>
                </button>
              </li>
              <hr className={cs(classes.boundary)} />
              <p className={cs(classes.mailText)}>{authorization.user.email}</p>
            </ul>
          </Popover>
        </>
      ) : (
        <>
          {isMobile ? (
            <MobileView>
              <>
                <div className={classes.menu} id={id} onClick={dropdown.open}>
                  <div className={classes.familyWrapper}>
                    <div className={classes.avatar}>
                      <Icon name="user" />
                    </div>
                  </div>
                </div>
                <Popover close={dropdown.close} style={dropdown.style}>
                  <ul className={cs(classes.dropdown, "bxsh-hard")}>
                    <li className={classes.item}>
                      <Link to={APPLICATION_ROUTES.SIGN_IN}>Sign In</Link>
                    </li>
                    <li className={classes.item}>
                      <Link to={APPLICATION_ROUTES.SIGN_UP}>Get Started</Link>
                    </li>
                  </ul>
                </Popover>
              </>
            </MobileView>
          ) : (
            <BrowserView>
              <div className={classes.authorization}>
                <div className={classes.signUp}>
                  <Link to={APPLICATION_ROUTES.SIGN_UP}>{t("components.molecules.profile_menu.labels.1")}</Link>
                </div>
                <div className={classes.signIn}>
                  <Button size="s" onClick={onNavigateToSignIn}>
                    {t("components.molecules.profile_menu.labels.2")}
                  </Button>
                </div>
              </div>
            </BrowserView>
          )}
        </>
      )}
    </div>
  );
});

ProfileMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ProfileMenu;
