/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import cs from "classnames";
import classes from "./WritingCompetitionForm.module.scss";
import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import WritingCompetitionLayout from "../../templates/WritingCompetitionLayout";

import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";
import maorIcon from "../../../assets/images/writing-competition-logo.png";
import BirthdayDetails from "./components/BirthdayDetails/BirthdayDetails";
import { StoreContext } from "../../../contexts";
import Characters from "./components/Characters";
import uploadCharacter from "../../../assets/images/upload-character.png";
import SettingsSection from "./components/SettingsSection";
import useForm from "./useForm";
import { observer } from "mobx-react-lite";
import { ACTIONS } from "../../../store";
import Loader from "../../atoms/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Loader2 } from "lucide-react";
import { WRITING_COMPETITON_DATA_KEY } from "../WritingCompetition/WritingCompetition";
import Location from "./components/Location/Location";
// import Icon, { NAMES, SIZES } from "../../atoms/Icon";

export const CHARACTER_SUBMISSION_KEY = "characterSubmission";

export const defaultCharacter = () => ({
  name: "",
  gender: "",
  age: "",
  height: "",
  livingPlace: "",
  favorite_mitzvah: "",
  favorite_food: "",
  dislikes: "",
  favorite_activity: "",
  favorite_yom_tov: "",
  happiness: "",
  good_at: "",
  wearing: "",
  fun_fact: "",
  family: "",
  additional_info: "",
});

export const defaultCharacterSettings = () => ({
  location: "",
  weather: "",
  transportation: "",
  rules_and_traditions: "",
  where_in_world: "",
  unique_aspects: "",
  additional_info: "",
  place_type: "",
  view: "",
  storyTime: "",
});

export const locationSettings = () => ({
  address: "",
});

const areSpecificCharacterFieldsValid = (characters, requiredFields) => {
  return characters.every((character) => {
    return requiredFields.every(
      (field) => character[field] !== "" && character[field] !== null && character[field] !== undefined
    );
  });
};

const areCharactersSubmitted = (characters) => {
  return characters.every((character) => {
    return character.status && character.status === "submitted";
  });
};

const WritingCompetitionForm = observer(() => {
  const { authorization, common, writingcompetition } = useContext(StoreContext);
  const history = useHistory();
  const {
    triggerValidationsWriting,
    triggerValidationsCharacterLocation,
    isNotValidPlaceSettings,
    uploadError,
    setUploadError,
    submitStory,
    isNotValidCharacterLocation,
    ...props
  } = useForm();
  const [submitError, setSubmitError] = useState([]);
  const [localUpload, setLocalUpload] = useState(false);
  const [draftSavedMessage, setDraftSavedMessage] = useState(false); // New state

  const getAction = ACTIONS.WRITING_COMPETITON.GET_SUBMISSION;

  useEffect(() => {
    if (authorization.isAuthenticated) {
      common.set(WRITING_COMPETITON_DATA_KEY, null);

      writingcompetition[`${getAction}`]();
    }
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    let submissionData = writingcompetition.submissionData;
    if (!submissionData || submissionData.length == 0) {
      // todo: if already submitted, redirected to success page.
      submissionData = {};

      let characters = Array(3)
        .fill(null)
        .map((_, index) => ({
          ...defaultCharacter(),
        }));

      submissionData.characters = [...characters];
      submissionData.setting = { ...defaultCharacterSettings() };
      submissionData.characters_photo = "";
      submissionData.selected_childs = [];
      submissionData.status = "pending";
      submissionData.location = { ...locationSettings() };
    }
    common.set(CHARACTER_SUBMISSION_KEY, submissionData);

    if (writingcompetition.submissionData?.status == "submitted" || !authorization.isAuthenticated) {
      history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION);
    }
  }, [writingcompetition.submissionData]);

  // useEffect(() => {
  //   const apiInterval = setInterval(() => {
  //     if (!writingcompetition[`isLoading${getAction}`]) {
  //       submitStory();
  //     }
  //   }, 10000);

  //   return () => {
  //     clearInterval(apiInterval);
  //   };
  // }, [submitStory]);

  useEffect(() => {
    if (writingcompetition[`success${ACTIONS.WRITING_COMPETITON.DRAFT_SUBMIT}`]) {
      setDraftSavedMessage(true);

      const timer = setTimeout(() => {
        setDraftSavedMessage(false);
      }, 3000);
      // Clean up the timer
      return () => clearTimeout(timer);
    }
  }, [writingcompetition[`success${ACTIONS.WRITING_COMPETITON.DRAFT_SUBMIT}`]]);

  const submissionData = common.get(CHARACTER_SUBMISSION_KEY);

  const handleImageUpload = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0]; // Take only the first file

      // Optional: Add file type and size validation
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(file.type)) {
        setUploadError("Please upload a valid image (JPEG, PNG, or GIF).");
        return;
      }

      if (file.size > maxSize) {
        setUploadError("Image size should be less than 5MB.");
        return;
      }

      // Clear previous error if upload is valid
      setUploadError(null);

      const updatedSubmissionData = {
        ...submissionData,
        characters_photo: file,
      };

      common.set(CHARACTER_SUBMISSION_KEY, updatedSubmissionData);

      setLocalUpload(true);
      submitStory();
    }
  };

  const updatedSettings = () => {
    const settingsMap = {
      location: props.placeName.value,
      weather: props.weatherLike.value,
      transportation: props.getAroundInput.value,
      rules_and_traditions: props.rulesAndTradition.value,
      where_in_world: props.whereInWorld.value,
      unique_aspects: props.unique.value,
      additional_info: props.moreAbout.value,
      place_type: props.getPlaceInput.value,
      view: props.getViewInput.value,
      storyTime: props.getStoryTimeRadio.value,
    };

    const locationMap = {
      address: props.characterFormLocation.value,
    };

    const updatedSetting = { ...settingsMap };
    const updatedLocation = { ...locationMap };

    common.set(CHARACTER_SUBMISSION_KEY, {
      ...submissionData,
      setting: updatedSetting,
      location: updatedLocation,
    });
  };

  const savePlaceDraft = () => {
    setSubmitError([]);
    updatedSettings();
    submitStory({ draftStatus: true });
  };

  const submitScriptHandler = () => {
    setSubmitError([]);
    updatedSettings();

    const requiredFields = [
      "name",
      "gender",
      "age",
      "height",
      "livingPlace",
      "favorite_mitzvah",
      "favorite_activity",
      "favorite_yom_tov",
      "good_at",
      "wearing",
    ];

    const errors = [];

    if (
      isNotValidPlaceSettings ||
      !submissionData.characters_photo ||
      submissionData.characters.length < 2 ||
      !submissionData.selected_childs ||
      submissionData.selected_childs.length == 0 ||
      !areSpecificCharacterFieldsValid(submissionData.characters, requiredFields) ||
      !areCharactersSubmitted(submissionData.characters)
    ) {
      if (!submissionData.characters_photo) {
        errors.push("Please Upload Character Photo");
      }
      if (!submissionData.selected_childs || submissionData.selected_childs.length === 0) {
        errors.push("Please select at least one child");
      }
      if (submissionData.characters.length < 2) {
        errors.push("Please submit at least three characters");
      } else if (!areSpecificCharacterFieldsValid(submissionData.characters, requiredFields)) {
        errors.push("Please ensure required fields are filled for each character");
      } else if (!areCharactersSubmitted(submissionData.characters)) {
        errors.push("Please submit your incomplete characters.");
      }

      if (isNotValidPlaceSettings) {
        triggerValidationsWriting();
        errors.push("Please fill out all place settings");
        console.log("submissionData = " + isNotValidPlaceSettings);
      }

      // if (isNotValidCharacterLocation) {
      //   triggerValidationsCharacterLocation();
      //   errors.push("Please fill out the location feilds");
      // }

      if (errors.length > 0) {
        setSubmitError(errors);
      } else {
        setSubmitError([]);
      }
    } else {
      const updatedSubmissionData = {
        ...submissionData,
        status: "submitted",
      };

      common.set(CHARACTER_SUBMISSION_KEY, updatedSubmissionData);

      submitStory({
        submissionDataStatus: true,
        onSucess: () => {
          history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION_SUCCESS);
        },
      });
    }

    // Validate character image
    if (!submissionData.characters_photo) {
      setUploadError("Please upload a character image.");
      return;
    }
  };

  return (
    <>
      <PageTitle value={PAGE_TITLES.WRITING_COMPETITION_FORM} />
      <Layout logotype="maor">
        <WritingCompetitionLayout>
          <>
            {writingcompetition[`isLoading${getAction}`] ||
            authorization.isLoadingSignIn ||
            authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] ? (
              <Loader />
            ) : (
              <>
                <div className={cs(classes.addWriters)}>
                  <h2 className={cs(classes.heading, classes.grandstanderFamily)}>Select Writers</h2>
                  {authorization.isAuthenticated && <BirthdayDetails {...props} />}
                  <h2 className={cs(classes.heading, classes.grandstanderFamily, "mt-05")}>Provide Your Location</h2>
                  <Location {...props} />
                </div>
                <div className={cs(classes.addCharacters)}>
                  <h2 className={cs(classes.heading, classes.grandstanderFamily)}>
                    Characters
                    <span>*Minimum 3, up to 5 charecters.</span>
                  </h2>
                  <Characters {...props} />

                  {/* Image Upload Section */}
                  <div>
                    <input
                      type="file"
                      id="characterImageUpload"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className={cs(classes.fileInput)}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="characterImageUpload"
                      className={cs(classes.uploadButton, classes.grandstanderFamily)}
                    >
                      <img src={uploadCharacter} alt="uploadCharacter" />
                      Upload Character Photo
                    </label>
                    <p className={cs(classes.note)}>*You can draw by hand or use any program.</p>

                    {/* Display uploaded image */}
                    {submissionData?.characters_photo && (
                      <div className={cs(classes.uploadedImagesContainer)}>
                        <div className={cs(classes.uploadedImageWrapper)}>
                          <img
                            src={
                              submissionData.characters_photo_preview && !localUpload
                                ? submissionData.characters_photo_preview
                                : URL.createObjectURL(submissionData.characters_photo)
                            }
                            alt="Uploaded Character"
                            className={cs(classes.uploadedImage)}
                          />
                        </div>
                      </div>
                    )}

                    {/* Error Message */}
                    {uploadError && <p className={cs(classes.submitError)}>{uploadError}</p>}
                  </div>

                  <div className={cs(classes.wFull, "wrapper-vertical-m")}>
                    <h2 className={cs(classes.heading, classes.grandstanderFamily)}>Place</h2>
                    <SettingsSection {...props} />

                    {submitError.length > 0 && (
                      <ul className={cs(classes.submitError)}>
                        {submitError.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    )}

                    <div className={cs(classes.formButtons)}>
                      <button
                        className={cs(classes.submitScriptButton, classes.saveDraft, classes.grandstanderFamily)}
                        onClick={savePlaceDraft}
                      >
                        {writingcompetition[`isLoading${ACTIONS.WRITING_COMPETITON.DRAFT_SUBMIT}`] ? (
                          <Loader2 className={cs(classes.loader)} />
                        ) : (
                          "Save Draft"
                        )}
                      </button>

                      <button
                        className={cs(classes.submitScriptButton, classes.grandstanderFamily)}
                        onClick={submitScriptHandler}
                      >
                        {writingcompetition[`isLoading${ACTIONS.WRITING_COMPETITON.FINAL_SUBMIT}`] ? (
                          <Loader2 className={cs(classes.loader)} />
                        ) : (
                          "Submit my Writing"
                        )}
                      </button>
                    </div>

                    {draftSavedMessage && <p className={cs(classes.draftSavedMessage)}>Draft Saved</p>}
                  </div>
                </div>
              </>
            )}
          </>
        </WritingCompetitionLayout>
      </Layout>
    </>
  );
});

export default WritingCompetitionForm;
