import React, { useState } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { COLORS, SIZES } from "./utilities";
import classes from "./PlainInputCharacter.module.scss";

const PlainInputCharacter = ({
  children: icon,
  color,
  error,
  id,
  size,
  stretched,
  // label,
  type,
  value,
  additionalClass,
  onBlur: onBlurCustom,
  onFocus: onFocusCustom,
  ...properties
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onBlur = () => {
    setIsFocused(false);
    onBlurCustom && onBlurCustom();
  };
  const onFocus = () => {
    setIsFocused(true);
    onFocusCustom && onFocusCustom();
  };

  return (
    <div
      className={cs(
        classes.container,
        classes[additionalClass],
        classes[color],
        classes[size],
        { [classes.stretched]: stretched }
      )}
    >
      {/* {label && (
        <>
          <div
            className={cs(classes.tape, {
              [classes.visible]: value || isFocused,
            })}
          >
            {label}
          </div>
          <label
            htmlFor={id}
            className={cs(classes.label, {
              [classes.floated]: value || isFocused,
            })}
          >
            {label}
          </label>
        </>
      )} */}

      <div className={classes.inputBox}>
        {properties.customComponent ? (
          properties.customComponent({
            id: { id },
            type: { type },
            className: cs("pac-target-input", classes.input, {
              [classes.error]: error,
              [classes.withIcon]: icon,
            }),
            onBlur: onBlur,
            onFocus: onFocus,
            autocomplete: "new-password",
            ...properties,
          })
        ) : (
          <>
            <input
              id={id}
              type={type}
              className={cs(classes.input, {
                // [classes.error]: error,
                [classes.withIcon]: icon,
              })}
              value={value}
              onBlur={onBlur}
              onFocus={onFocus}
              autoComplete="new-password"
              {...properties}
            />
          </>
        )}
        {icon && (
          <div className={cs(classes.icon, { [classes.error]: error })}>
            {icon}
          </div>
        )}
      </div>

      {!isFocused && error && <></>}
    </div>
  );
};

PlainInputCharacter.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(Object.values(COLORS)),
  error: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  stretched: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.oneOf(["number", "password", "text", "date"]),
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  additionalClass: PropTypes.string,
  customAutoComplete: PropTypes.bool,
};

PlainInputCharacter.defaultProps = {
  children: null,
  color: COLORS.PRIMARY,
  error: "",
  size: SIZES.MEDIUM,
  stretched: false,
  label: "",
  type: "text",
  additionalClass: "",
  customAutoComplete: false,
};

export default PlainInputCharacter;
