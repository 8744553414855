/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import cs from "classnames";
import classes from "./WinnerItem.module.scss";
import PropTypes from "prop-types";
import playIcon from "../../../../../assets/images/competition-play.png";
import Modal from "../../../../organisms/Modal";
import Popup from "../../../../atoms/Popup";
import { useModal } from "../../../../../hooks";
import { StoreContext } from "../../../../../contexts";
import { formatChildNames, getFirstChildLocation, WRITING_COMPETITON_DATA_KEY } from "../../WritingCompetition";
// import thumb from "../../../../../assets/images/thumb.webp";
import { ACTIONS } from "../../../../../store";
import { Loader2 } from "lucide-react";
import { observer } from "mobx-react-lite";
import CharactersModal from "../CharactersModal/CharactersModal";
import Icon, { NAMES } from "../../../../atoms/Icon";
import WinnerIcon from "../../../../../assets/images/winner-icon.png";

const WinnerItem = observer(({ script, playerBgColor, handleWelcomeModal, winner_photo = "" }) => {
  const video = useModal();
  const { authorization, common, writingcompetition } = useContext(StoreContext);
  const charactersFormModal = useModal();
  const handleVideoPlay = () => {
    video.onOpen();
  };

  const childNames = formatChildNames(script?.selected_childs);

  const writingCompetitionData = common.get(WRITING_COMPETITON_DATA_KEY);

  return (
    <>
      <div className={cs(classes.videoItemContainer)} style={{ backgroundColor: playerBgColor }}>
        {/* Header */}
        <div className={cs(classes.header)}>
          <h2 className={cs(classes.names, classes.grandstanderFamily)}>{childNames || "Unnamed Character"}</h2>
          <p className={cs(classes.location)}> {script?.location?.address && `${script.location.address} `}</p>
        </div>

        <div className={cs(classes.winnerIcon)}>
          <img src={winner_photo} alt="WinnerIcon" />
        </div>

        {/* {(writingCompetitionData?.details?.stage === "Voting" ||
          writingCompetitionData?.details?.stage === "Award Show") && (
          <div className={cs(classes.voteInfo)}>
            <img src={thumb} alt="thumb" />
            <p>{script?.vote_count}</p>
          </div>
        )} */}

        {/* Video Content */}
        <div className={cs(classes.videoContent)}>
          <video
            className={cs(classes.videoThumbnail)}
            src={script?.video_submission?.data?.video}
            muted
            loop
            playsInline
            onLoadedData={(e) => e.target.pause()} // Pause to show first frame
          >
            Your browser does not support the video tag.
          </video>
          <button
            className={cs(classes.playButton)}
            aria-label={`Play video for ${childNames || ""}`}
            onClick={handleVideoPlay}
          >
            <img src={playIcon} alt="playIcon" />
          </button>
        </div>

        {/* Script Button */}
        <div className={cs(classes.buttons)}>
          <div
            onClick={() => {
              common.set("currentCharacter", script);

              charactersFormModal.onOpen();
            }}
            className={cs(classes.scriptButton, classes.grandstanderFamily)}
          >
            Characters
          </div>

          <div
            onClick={() => {
              window.open(script?.video_submission?.data?.script, "_blank");
            }}
            className={cs(classes.scriptButton, classes.grandstanderFamily)}
          >
            Script
          </div>
        </div>
      </div>

      {/* Modal with Video */}
      <Modal opened={video.isOpened} onClose={video.onClose}>
        <Popup popup={video}>
          <div className={cs(classes.videoModalContent)}>
            <video controls autoPlay className={cs(classes.videoPlayer)} src={script?.video_submission?.data?.video}>
              Your browser does not support the video tag.
            </video>
          </div>
        </Popup>
      </Modal>

      <Modal opened={charactersFormModal.isOpened}>
        <div className={cs(classes.addCharacterModalConatiner)}>
          <div className={cs(classes.addCharacterModalCard)}>
            <CharactersModal />
            <div
              className={cs(classes.iconCross)}
              onClick={() => {
                charactersFormModal.onClose();
              }}
            >
              <Icon name={NAMES.X} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

WinnerItem.propTypes = {
  script: PropTypes.shape({
    id: PropTypes.number.isRequired,
    names: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }).isRequired,
  playerBgColor: PropTypes.string,
};

export default WinnerItem;
