const ACTIONS = {
  GET: "get",
  SUBMIT: "submit",
  FINAL_SUBMIT: "final_submit",
  DRAFT_SUBMIT: "draft_submit",
  GET_SUBMISSION: "getSubmission",
  SUBMIT_SCRIPT: "submitScript",
  CHARACTER_SUBMIT: "charactersubmit",
  VOTE: "vote",
};

export { ACTIONS };
