/* eslint-disable */

import React from "react";
import cs from "classnames";
import classes from "./WritingCompetitionLayout.module.scss";
import maorIcon from "../../../assets/images/writing-competition-logo.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { APPLICATION_ROUTES } from "../../../constants";

const WritingCompetitionLayout = ({ children }) => {
  const history = useHistory();

  return (
    <div className={cs(classes.bgContainer)}>
      <div className="wrapper-m wrapper-vertical-m">
        <div className={cs(classes.maorCompetitionLogoContainer)}>
          <img onClick={() => history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION)} src={maorIcon} alt="maorIcon" className={cs(classes.maorCompetitionLogo)} />
        </div>
        <div className={cs(classes.writingCompetitionContainer)}>{children}</div>
      </div>
    </div>
  );
};


export default WritingCompetitionLayout;
