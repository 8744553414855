/* eslint-disable no-prototype-builtins */
/**
 * Converts a JSON object into FormData
 * @param {Object} data - The JSON object to convert
 * @param {FormData} [formData] - The FormData instance (optional, for recursion)
 * @param {string} [parentKey] - The parent key for nested objects (used internally)
 * @returns {FormData} - The resulting FormData
 */
function jsonToFormData(data, formData = new FormData(), parentKey = "") {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      const formKey = parentKey ? `${parentKey}[${key}]` : key;

      if (value instanceof File) {
        // If value is a File object, append it directly
        formData.append(formKey, value);
      } else if (
        value &&
        typeof value === "object" &&
        !(value instanceof Date)
      ) {
        // If value is an object or array, recurse
        jsonToFormData(value, formData, formKey);
      } else if (value instanceof Date) {
        // Format Date objects as ISO strings
        formData.append(formKey, value.toISOString());
      } else if (value === null) {
        // Append null values as empty strings
        formData.append(formKey, "");
      } else if (value !== undefined) {
        // Append primitive types (number, string, boolean)
        formData.append(formKey, value);
      }
    }
  }
  return formData;
}

export default jsonToFormData;
