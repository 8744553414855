import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";

import cs from "classnames";
import classes from "./WritingCompetition.module.scss";
import CharacterSubmissionItem from "./components/CharacterSubmissionItem";
import VideoSubmissionItem from "./components/VideoSubmissionItem";
import liveGif from "../../../assets/gifs/live.gif";
// import ProgressBar from "./components/ProgressBar";
import maorLogo from "../../../assets/images/my-maor-new-logo.png";
import maorIcon from "../../../assets/images/writing-competition-logo.png";
import NewProgressBar from "./components/NewProgressBar";
import ToichenOptions from "./components/ToichenOptions";
import { isMobile } from "react-device-detect";
import playIcon from "../../../assets/images/competition-play.png";
import Player from "../../organisms/Player";
import Modal from "../../organisms/Modal";
import { useModal } from "../../../hooks";
import Popup from "../../atoms/Popup";
import CompetitionLogInModal from "./components/CompetitionLogInModal";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import Loader from "../../atoms/Loader";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import WinnerItem from "./components/WinnerItem";
import ribbon from "../../../assets/images/pink-ribbon.png";

const submissionColors = [
  "#87BA48",
  "#FFDE59",
  "#FF666B",
  "#FFAE43",
  "#F472D0",
  "#6EC887",
  "#49BEBC",
  "#B29367",
  "#0097B2",
];

export const stageColors = {
  "Character Building": "#d755b3", // Greenish
  "Script Submission": "#87ba48", // Yellow
  Voting: "#49bebc", // Red
  "Award Show": "#FFDE59", // Orange
};

export const stage = {
  CHARACTER_BUILDING: "Character Building",
  SCRIPT_SUBMISSION: "Script Submission",
  VOTING: "Voting",
  AWARD_SHOW: "Award Show",
};

const BUTTON_STATUS = {
  SUBMITTED: "submitted",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
};

export const formatChildNames = (selected_childs) => {
  if (!selected_childs || selected_childs.length === 0) return "Unnamed Character";

  // Check if all children have the same last name
  const uniqueLastNames = new Set(selected_childs.map((child) => child.last_name || ""));

  if (uniqueLastNames.size === 1) {
    // All children have the same last name
    const lastName = [...uniqueLastNames][0]; // Extract the common last name
    const firstNames = selected_childs
      .map((child) => child.first_name)
      .join(", ")
      .replace(/, ([^,]*)$/, " and $1"); // Add "and" before the last child's first name
    return `${firstNames} ${lastName}`.trim();
  } else {
    // Existing logic for different last names
    const childNames = selected_childs.map((child) => `${child.first_name} ${child.last_name || ""}`.trim());

    if (childNames.length > 1) {
      return `${childNames.slice(0, -1).join(", ")} and ${childNames[childNames.length - 1]}`;
    } else {
      return childNames[0];
    }
  }
};

export const getFirstChildLocation = (children) => {
  if (!Array.isArray(children) || children.length === 0) {
    return "No children available.";
  }

  const firstChild = children[0]; // Get the first child object
  const { city, state, country } = firstChild;

  // Filter out null/undefined/empty values and join them with commas
  return [city, state, country].filter(Boolean).join(", ");
};

export const WRITING_COMPETITON_DATA_KEY = "writingCompetitionData";

const WritingCompetition = observer(() => {
  const [isPlaying, setIsPlaying] = useState(false); // Video playback state
  const [videoUrl, setVideoUrl] = useState(""); // Video URL state
  const history = useHistory();
  const { authorization, writingcompetition, common } = useContext(StoreContext);
  const [currentCharacter, setCurrentCharacter] = useState(null);

  const welcomeModal = useModal();
  const writingCompetitionData = common.get(WRITING_COMPETITON_DATA_KEY);

  const handleWelcomeModal = () => {
    welcomeModal.onOpen();
  };

  const handlePlayVideo = () => {
    setVideoUrl(writingCompetitionData?.details?.intro_video); // Replace with the desired video URL
    setIsPlaying(true); // Show the Player
  };

  const getAction = ACTIONS.WRITING_COMPETITON.GET;

  useEffect(() => {
    if (
      authorization[`success${ACTIONS.AUTHORIZATION.RESIGN_IN}`] ||
      authorization[`success${ACTIONS.AUTHORIZATION.SIGN_IN}`] ||
      authorization.isAuthenticated
    ) {
      writingcompetition[`${getAction}`](authorization.isAuthenticated);
    } else {
      writingcompetition[`${getAction}`]();
    }
  }, [
    authorization.isAuthenticated,
    authorization[`success${ACTIONS.AUTHORIZATION.RESIGN_IN}`],
    authorization[`success${ACTIONS.AUTHORIZATION.SIGN_IN}`],
  ]);

  useEffect(() => {
    if (writingcompetition.data) {
      common.set(WRITING_COMPETITON_DATA_KEY, writingcompetition.data);
    }
  }, [writingcompetition.data, writingcompetition[`isLoading${getAction}`]]);

  const handleCharacterClick = (character) => {
    setCurrentCharacter(character);
  };

  useEffect(() => {
    common.set("currentCharacter", currentCharacter);
  }, [currentCharacter]);

  const onScrollToVideoSection = () => {
    document.querySelector("#scriptSubmission").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <PageTitle value={PAGE_TITLES.WRITINNG_COMPETITION} />
      <Layout logotype="maor">
        <div className="bg-white">
          <div className={cs(classes.bannerContainer)}>
            <div className={cs(classes.bannerRight)}>
              <img src={maorLogo} alt="maorLogo" className={cs(classes.maorLogo)} />
              <img src={liveGif} alt="liveGif" className={cs(classes.liveGif)} />
            </div>
            <div className={cs(classes.bannerCenter)}>
              <img src={maorIcon} alt="maorIcon" className={cs(classes.maorCompetitionLogo)} />
            </div>
            <div className={cs(classes.bannerLeft)}>
              <div className={cs(classes.competitionCard, classes.grandstanderFamily)}>
                How does the <br /> competition work?
              </div>
            </div>
          </div>

          {writingcompetition[`isLoading${getAction}`] ||
          authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] ||
          authorization[`isLoading${ACTIONS.AUTHORIZATION.SIGN_IN}`] ? (
            <div className="wrapper-vertical-m">
              <Loader />
            </div>
          ) : (
            <div>
              <div className="mt-10 wrapper-m">
                <NewProgressBar />
              </div>
              <div className="wrapper-m">
                <div className={cs(classes.progressContainer)}></div>
                <div className={cs(classes.videoSection)}>
                  {writingCompetitionData?.details?.stage == "Character Building"}
                  {writingCompetitionData?.details?.stage == stage.AWARD_SHOW ? (
                    <>
                      {writingCompetitionData?.details?.intro_video_thumbnail ? (
                        <div
                          className={cs(classes.videoContainer)}
                          style={{ backgroundColor: stageColors[writingCompetitionData?.details?.stage] }}
                        >
                          {!isPlaying ? (
                            <div
                              className={cs(classes.videoContent)}
                              style={{
                                backgroundImage: `url(${writingCompetitionData?.details?.intro_video_thumbnail})`,
                              }}
                            >
                              <button
                                className={cs(classes.playButton)}
                                onClick={handlePlayVideo} // Trigger playback on click
                              >
                                <img src={playIcon} alt="Play Icon" />
                              </button>
                            </div>
                          ) : (
                            <Player
                              video={videoUrl} // Pass the video URL
                              autoplay
                              additionalClass={isMobile ? "mobileHeight" : "desktopHeight"}
                              muted={isMobile ? true : false}
                              playerSize="regularPlayer"
                            />
                          )}
                        </div>
                      ) : (
                        <div
                          className={cs(classes.videoContainer)}
                          style={{ backgroundColor: stageColors[writingCompetitionData?.details?.stage] }}
                        >
                          <img src={writingCompetitionData?.details?.intro_image} alt="img" />
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      className={cs(classes.videoContainer)}
                      style={{ backgroundColor: stageColors[writingCompetitionData?.details?.stage] }}
                    >
                      {!isPlaying ? (
                        <div
                          className={cs(classes.videoContent)}
                          style={{
                            backgroundImage: `url(${writingCompetitionData?.details?.intro_video_thumbnail})`,
                          }}
                        >
                          <button
                            className={cs(classes.playButton)}
                            onClick={handlePlayVideo} // Trigger playback on click
                          >
                            <img src={playIcon} alt="Play Icon" />
                          </button>
                        </div>
                      ) : (
                        <Player
                          video={videoUrl} // Pass the video URL
                          autoplay
                          additionalClass={isMobile ? "mobileHeight" : "desktopHeight"}
                          muted={isMobile ? true : false}
                          playerSize="regularPlayer"
                        />
                      )}
                    </div>
                  )}
                  <div className={cs(classes.videoInfo)}>
                    <div className={cs(classes.videoText)}>
                      <h1 className={cs(classes.grandstanderFamily)}> {writingCompetitionData?.details?.text}</h1>
                      <p className={cs(classes.videoDescription)}>{writingCompetitionData?.details?.description}</p>
                    </div>
                    {writingCompetitionData?.details?.stage == "Character Building" && (
                      <>
                        {writingCompetitionData?.status == BUTTON_STATUS.SUBMITTED ? (
                          <>
                            {writingCompetitionData?.status === BUTTON_STATUS.SUBMITTED && (
                              <p
                                className={cs(
                                  classes.statusDescription,
                                  writingCompetitionData?.character_submission_status === BUTTON_STATUS.ACCEPTED
                                    ? classes.greenBg
                                    : writingCompetitionData?.character_submission_status === BUTTON_STATUS.REJECTED
                                    ? classes.redBg
                                    : classes.grayBg
                                )}
                              >
                                {writingCompetitionData?.character_submission_status === BUTTON_STATUS.ACCEPTED ? (
                                  <>
                                    Your submission has <br /> been accepted
                                  </>
                                ) : writingCompetitionData?.character_submission_status === BUTTON_STATUS.REJECTED ? (
                                  <>
                                    Your submission was declined.
                                    <span>Check your email for more details.</span>
                                  </>
                                ) : (
                                  <>
                                    Your submission <br /> is in review
                                  </>
                                )}
                              </p>
                            )}
                          </>
                        ) : (
                          <button
                            onClick={() => {
                              if (authorization.isAuthenticated) {
                                history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION_FORM);
                              } else {
                                handleWelcomeModal();
                              }
                            }}
                            className={cs(classes.joinButton, classes.grandstanderFamily)}
                          >
                            {writingCompetitionData?.details?.button}
                          </button>
                        )}
                      </>
                    )}
                    {writingCompetitionData?.details?.stage == "Script Submission" && (
                      <>
                        {!writingCompetitionData?.character_submission_status ? (
                          <p className={cs(classes.statusDescription)}>
                            Sorry, but you cannot submit the script as this account did not provide character
                            information in Character Building.
                          </p>
                        ) : (
                          <>
                            {writingCompetitionData?.character_submission_status == BUTTON_STATUS.ACCEPTED ? (
                              <>
                                {writingCompetitionData?.video_submission_status ? (
                                  <>
                                    {writingCompetitionData?.video_submission_status == BUTTON_STATUS.SUBMITTED ? (
                                      <p className={cs(classes.statusDescription, classes.grayBg)}>
                                        Your submission <br /> is in review
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {writingCompetitionData?.video_submission_status == BUTTON_STATUS.ACCEPTED ? (
                                      <p className={cs(classes.statusDescription, classes.greenBg)}>
                                        Your submission has <br /> been accepted
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {writingCompetitionData?.video_submission_status == BUTTON_STATUS.REJECTED ? (
                                      <p className={cs(classes.statusDescription, classes.redBg)}>
                                        Your submission was declined.
                                        <span>Check your email for more details.</span>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <button
                                    onClick={() => {
                                      if (authorization.isAuthenticated) {
                                        history.push(APPLICATION_ROUTES.WRITING_COMPETITON_VIDEO_FORM);
                                      } else {
                                        handleWelcomeModal();
                                      }
                                    }}
                                    className={cs(classes.joinButton, classes.grandstanderFamily)}
                                  >
                                    {writingCompetitionData?.details?.button}
                                  </button>
                                )}
                              </>
                            ) : (
                              <>
                                <p className={cs(classes.statusDescription)}>
                                  {writingCompetitionData?.character_submission_status == BUTTON_STATUS.REJECTED
                                    ? "Your character submission was not accepted."
                                    : "Your character submission was not accepted."}
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {writingCompetitionData?.details?.stage == "Voting" && (
                      <button
                        onClick={() => {
                          if (authorization.isAuthenticated) {
                            onScrollToVideoSection();
                          } else {
                            handleWelcomeModal();
                          }
                        }}
                        className={cs(classes.joinButton, classes.grandstanderFamily)}
                      >
                        {writingCompetitionData?.details?.button}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {writingCompetitionData?.details?.stage == "Script Submission" &&
                writingCompetitionData?.details?.toichen_buttons && (
                  <div className="wrapper-m mt-13">
                    <ToichenOptions />
                  </div>
                )}

              <div className="wrapper-vertical-m">
                {writingCompetitionData?.details?.stage === "Award Show" && (
                  <div className={cs(classes.winnerContainer, "wrapper-vertical-m")}>
                    {!writingCompetitionData?.details?.is_winner_selected && (
                      <div className={cs(classes.winnerOverlay)}>
                        <div className={cs(classes.ribbonContainer)}>
                          <p> STAY TUNED FOR </p>
                          <img className={cs(classes.ribbonImage)} src={ribbon} alt="Ribbon" />

                          <p className={cs(classes.mlLess)}> THE WINNERS VIDEO </p>
                        </div>
                      </div>
                    )}
                    <h2 className={cs(classes.submissionHeader, classes.grandstanderFamily)}>
                      <span className={cs(classes.submissionHeaderText, classes.red, classes.bgYellow)}>
                        WINNING VIDEO
                      </span>
                    </h2>
                    <div className={cs(classes.winnerContent, "wrapper-m")}>
                      <WinnerItem
                        script={writingCompetitionData?.details?.selected_winner}
                        winner_photo={writingCompetitionData?.details?.winner_photo}
                      />
                      <div className={cs(classes.videoContainer)} style={{ backgroundColor: "#ffd52d" }}>
                        {!isPlaying ? (
                          <div
                            className={cs(classes.winnerVideoContent)}
                            style={{
                              backgroundImage: `url(${writingCompetitionData?.details?.winner_video_thumbnail})`,
                            }}
                          >
                            <button
                              className={cs(classes.playButton)}
                              onClick={handlePlayVideo} // Trigger playback on click
                            >
                              <img src={playIcon} alt="Play Icon" />
                            </button>
                          </div>
                        ) : (
                          <Player
                            video={writingCompetitionData?.details?.winner_video} // Pass the video URL
                            autoplay
                            additionalClass={isMobile ? "mobileHeight" : "desktopHeight"}
                            muted={isMobile ? true : false}
                            playerSize="regularPlayer"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="wrapper-m">
                  {writingCompetitionData?.details?.stage === "Award Show" &&
                    writingCompetitionData?.leading_submissions?.length > 0 && (
                      <div className={cs(classes.SubmissionContainer, "mt-13")} id="scriptSubmission">
                        <h2 className={cs(classes.submissionHeader, classes.grandstanderFamily)}>
                          <span className={cs(classes.submissionHeaderText, classes.red)}>Runner Ups</span>
                          {/* <div className={classes.submissionHeaderLineWrapper}>
                          <div className={classes.submissionHeaderLine}></div>
                        </div> */}
                        </h2>
                        <div className={`${cs(classes.grid)} ${cs(classes.gridSm)} ${cs(classes.gridLg)}`}>
                          {writingCompetitionData?.leading_submissions.map((script, index) => {
                            const playerBgColor = submissionColors[index];
                            return (
                              <VideoSubmissionItem
                                key={script.id || index} // Use 'script.id' if available for a unique key
                                script={script}
                                playerBgColor={playerBgColor}
                                handleWelcomeModal={handleWelcomeModal}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}

                  {(writingCompetitionData?.details?.stage === "Script Submission" ||
                    writingCompetitionData?.details?.stage === "Voting" ||
                    writingCompetitionData?.details?.stage === "Award Show") &&
                    writingCompetitionData?.video_submissions?.length > 0 && (
                      <div
                        className={cs(
                          classes.SubmissionContainer,
                          writingCompetitionData?.details?.stage === "Award Show" && "mt-13"
                        )}
                        id="scriptSubmission"
                      >
                        <h2 className={cs(classes.submissionHeader, classes.grandstanderFamily)}>
                          <span className={cs(classes.submissionHeaderText)}>
                            {writingCompetitionData?.details?.stage == "Voting" ? "Submissions" : "SCRIPT SUBMISSIONS"}
                          </span>
                          <div className={classes.submissionHeaderLineWrapper}>
                            <div className={classes.submissionHeaderLine}></div>
                          </div>
                        </h2>
                        <div className={`${cs(classes.grid)} ${cs(classes.gridSm)} ${cs(classes.gridLg)}`}>
                          {writingCompetitionData.video_submissions.map((script, index) => {
                            const playerBgColor = submissionColors[index];
                            return (
                              <VideoSubmissionItem
                                key={script.id || index} // Use 'script.id' if available for a unique key
                                script={script}
                                playerBgColor={playerBgColor}
                                handleWelcomeModal={handleWelcomeModal}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}

                  {(writingCompetitionData?.details?.stage === "Script Submission" ||
                    writingCompetitionData?.details?.stage === "Character Building") &&
                    writingCompetitionData?.character_submissions?.length > 0 && (
                      <div
                        className={cs(
                          classes.SubmissionContainer,
                          writingCompetitionData?.details?.stage === "Script Submission" && "wrapper-vertical-m"
                        )}
                      >
                        <h2 className={cs(classes.submissionHeader, classes.grandstanderFamily)}>
                          <span className={cs(classes.submissionHeaderText)}>CHARACTER SUBMISSIONS</span>
                          <div className={classes.submissionHeaderLineWrapper}>
                            <div className={classes.submissionHeaderLine}></div>
                          </div>
                        </h2>

                        <div
                          className={`${cs(classes.grid)} ${cs(classes.gridSm)} ${cs(classes.gridM)} ${cs(
                            classes.characterGridLg
                          )}`}
                        >
                          {writingCompetitionData.character_submissions.map((character, index) => {
                            const bgColor = submissionColors[index];

                            return (
                              <CharacterSubmissionItem
                                key={character.id} // Ensure 'id' is unique
                                character={character}
                                bgColor={bgColor}
                                onCharacterClick={handleCharacterClick}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>

      <Modal opened={welcomeModal.isOpened} onClose={welcomeModal.onClose}>
        <Popup
          popup={welcomeModal}
          cardStyles={{
            maxWidth: "600px",
            padding: "50px ",
          }}
        >
          <CompetitionLogInModal />
        </Popup>
      </Modal>
    </>
  );
});

export default WritingCompetition;
