/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";
import cs from "classnames";
import classes from "./VideoSubmissionItem.module.scss";
import PropTypes from "prop-types";
import playIcon from "../../../../../assets/images/competition-play.png";
import Modal from "../../../../organisms/Modal";
import Popup from "../../../../atoms/Popup";
import { useModal } from "../../../../../hooks";
import { StoreContext } from "../../../../../contexts";
import { formatChildNames, WRITING_COMPETITON_DATA_KEY } from "../../WritingCompetition";
import thumb from "../../../../../assets/images/thumb.webp";
import { ACTIONS } from "../../../../../store";
import { Loader2 } from "lucide-react";
import { observer } from "mobx-react-lite";

const VideoSubmissionItem = observer(({ script, playerBgColor, handleWelcomeModal }) => {
  const video = useModal();
  const voteErrorModal = useModal();
  const { authorization, common, writingcompetition } = useContext(StoreContext);
  const [voted, setVoted] = useState(false);
  const [submissionVoteId, setSubmissionVoteId] = useState(0);
  // const [voteError, setVoteError] = useState("");

  const handleVideoPlay = () => {
    video.onOpen();
  };

  const childNames = formatChildNames(script?.selected_childs);

  const writingCompetitionData = common.get(WRITING_COMPETITON_DATA_KEY);

  const voteSubmit = () => {
    setSubmissionVoteId(script.id);
    // setVoteError("");
    writingcompetition[`${ACTIONS.WRITING_COMPETITON.VOTE}`]({
      submissionID: script.id,
      onSucess: (status, message) => {
        setSubmissionVoteId(0);
        if (!status) {
          // setVoteError(message);
          voteErrorModal.onOpen();
        } else {
          setVoted(true);
        }
      },
    });
  };

  const voteCount = voted ? script?.vote_count + 1 : script?.vote_count;

  return (
    <>
      <div key={script.id} className={cs(classes.videoItemContainer)} style={{ backgroundColor: playerBgColor }}>
        {/* Header */}
        <div className={cs(classes.header)}>
          <h2 className={cs(classes.names, classes.grandstanderFamily)}>{childNames || "Unnamed Character"}</h2>
          <p className={cs(classes.location)}>{script?.location?.address && `${script.location.address} `}</p>
        </div>

        {(writingCompetitionData?.details?.stage === "Voting" ||
          writingCompetitionData?.details?.stage === "Award Show") && (
          <div className={cs(classes.voteInfo)}>
            <img src={thumb} alt="thumb" />
            <p>{voteCount}</p>
          </div>
        )}

        {/* Video Content */}
        <div className={cs(classes.videoContent)}>
          <video
            className={cs(classes.videoThumbnail)}
            src={script?.video_submission?.data?.video}
            muted
            loop
            playsInline
            onLoadedData={(e) => e.target.pause()} // Pause to show first frame
          >
            Your browser does not support the video tag.
          </video>
          <button
            className={cs(classes.playButton)}
            aria-label={`Play video for ${childNames || ""}`}
            onClick={handleVideoPlay}
          >
            <img src={playIcon} alt="playIcon" />
          </button>
        </div>

        {/* Script Button */}
        <div
          onClick={() => {
            window.open(script?.video_submission?.data?.script, "_blank");
          }}
          className={cs(classes.scriptButton, classes.grandstanderFamily)}
        >
          Script
        </div>

        {writingCompetitionData?.details?.stage !== "Award Show" && (
          <div
            onClick={() => {
              if (authorization.isAuthenticated) {
                if (writingCompetitionData?.details?.stage == "Voting") {
                  voteSubmit();
                } else {
                  return null;
                }
              } else {
                handleWelcomeModal();
              }
            }}
            className={cs(
              classes.voteButton,
              classes.grandstanderFamily,
              writingCompetitionData?.details?.stage == "Voting" && !script?.has_voted && !voted && classes.activeButton
            )}
          >
            {writingcompetition[`isLoading${ACTIONS.WRITING_COMPETITON.VOTE}`] && submissionVoteId == script.id ? (
              <Loader2 className={cs(classes.loader)} />
            ) : (
              <>{script?.has_voted || voted ? " Voted " : " Vote for me! "}</>
            )}
          </div>
        )}
      </div>

      {/* Modal with Video */}
      <Modal opened={video.isOpened} onClose={video.onClose}>
        <Popup popup={video}>
          <div className={cs(classes.videoModalContent)}>
            <video controls autoPlay className={cs(classes.videoPlayer)} src={script?.video_submission?.data?.video}>
              Your browser does not support the video tag.
            </video>
          </div>
        </Popup>
      </Modal>
      <Modal opened={voteErrorModal.isOpened} onClose={voteErrorModal.onClose}>
        <Popup popup={voteErrorModal} cardStyles={{ maxWidth: "600px" }}>
          <div className={cs(classes.errorModalContent)}>
            <p>
              <strong>Error:</strong> Voting limit reached! Come back in 24 hours to vote again - your participation
              matters!
            </p>
          </div>
        </Popup>
      </Modal>
    </>
  );
});

VideoSubmissionItem.propTypes = {
  script: PropTypes.shape({
    id: PropTypes.number.isRequired,
    names: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }).isRequired,
  playerBgColor: PropTypes.string,
};

export default VideoSubmissionItem;
