import React, { useState } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { COLORS, SIZES } from "./utilities";
import classes from "./TextAreaCharacter.module.scss";

const TextAreaCharacter = ({
  children: icon,
  color,
  error,
  id,
  max,
  size,
  stretched,
  title,
  value,
  onBlur: onBlurCustom,
  onFocus: onFocusCustom,
  ...properties
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onBlur = () => {
    setIsFocused(false);
    onBlurCustom && onBlurCustom();
  };
  const onFocus = () => {
    setIsFocused(true);
    onFocusCustom && onFocusCustom();
  };

  return (
    <div className={cs(classes.container, classes[color], classes[size], { [classes.stretched]: stretched })}>
      <div className={classes.inputBox}>
        <textarea
          className={cs(classes.input, { [classes.error]: error, [classes.withIcon]: icon })}
          id={id}
          maxLength={max}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          {...properties}
        />
        {icon && <span className={cs(classes.icon, { [classes.error]: error })}>{icon}</span>}
      </div>
      {(max || title) && (
        <div className={classes.title}>
          {max && `${value.length}/${max}`} {title}
        </div>
      )}

      {!isFocused && error && <div className={classes.tooltip}>{error}</div>}
    </div>
  );
};

TextAreaCharacter.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(Object.values(COLORS)),
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.oneOf(Object.values(SIZES)),
  stretched: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

TextAreaCharacter.defaultProps = {
  children: null,
  color: COLORS.PRIMARY,
  error: "",
  label: "",
  max: "",
  size: SIZES.MEDIUM,
  stretched: false,
  title: "",
};

export default TextAreaCharacter;
