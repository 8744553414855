import { makeAutoObservable } from "mobx";
import { WritingCompetitionAPI } from "../../api";
import { ACTIONS } from "./utilities";

const writingCompetitionAPI = new WritingCompetitionAPI();

const WritingCompetition = class {
  constructor() {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.data = null;
    this.submissionData = null;

    makeAutoObservable(this);
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setSuccess(name, value) {
    this[`success${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  get(userAuthenticated = false) {
    this.setIsLoading(ACTIONS.GET, true);

    writingCompetitionAPI
      .get(userAuthenticated)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        console.log("details", data);
        this.setValue("data", data);
        this.setIsLoading(ACTIONS.GET, false);
      })
      .catch((error) => {
        this.setError(ACTIONS.GET, error.message);
        this.setIsLoading(ACTIONS.GET, false);
      });
  }

  getSubmission() {
    this.setIsLoading(ACTIONS.GET_SUBMISSION, true);
    this.setSuccess(ACTIONS.GET_SUBMISSION, false);
    this.setError(ACTIONS.GET_SUBMISSION, false);

    writingCompetitionAPI
      .getSubmission()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        console.log("details", data);
        this.setValue("submissionData", data);
        this.setIsLoading(ACTIONS.GET_SUBMISSION, false);
        this.setSuccess(ACTIONS.GET_SUBMISSION, true);
      })
      .catch((error) => {
        this.setError(ACTIONS.GET_SUBMISSION, error.message);
        this.setIsLoading(ACTIONS.GET_SUBMISSION, false);
      });
  }

  submit({ exFormData, submissionDataStatus, draftStatus, characterSubmitStatus, onSucess }) {
    this.setIsLoading(ACTIONS.SUBMIT, true);
    this.setSuccess(ACTIONS.SUBMIT, false);
    this.setError(ACTIONS.SUBMIT, false);

    if (submissionDataStatus) {
      this.setSuccess(ACTIONS.FINAL_SUBMIT, false);
      this.setIsLoading(ACTIONS.FINAL_SUBMIT, true);
    }

    if (draftStatus) {
      this.setSuccess(ACTIONS.DRAFT_SUBMIT, false);
      this.setIsLoading(ACTIONS.DRAFT_SUBMIT, true);
    }

    if (characterSubmitStatus) {
      this.setSuccess(ACTIONS.CHARACTER_SUBMIT, false);
      this.setIsLoading(ACTIONS.CHARACTER_SUBMIT, true);
    }

    writingCompetitionAPI
      .submit(exFormData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (submissionDataStatus) {
            this.setSuccess(ACTIONS.FINAL_SUBMIT, true);
          }
          if (draftStatus) {
            this.setSuccess(ACTIONS.DRAFT_SUBMIT, true);
          }
          if (characterSubmitStatus) {
            this.setSuccess(ACTIONS.CHARACTER_SUBMIT, true);
            this.setIsLoading(ACTIONS.CHARACTER_SUBMIT, false);
          }
          onSucess && onSucess();
          return response.json();
        }
        throw new Error(response.status);
      })
      .then(() => {
        console.log("Submit character", this[`success${ACTIONS.SUBMIT}`]);
        this.setIsLoading(ACTIONS.SUBMIT, false);
        this.setSuccess(ACTIONS.SUBMIT, true);
        if (submissionDataStatus) {
          this.setSuccess(ACTIONS.FINAL_SUBMIT, true);
          this.setIsLoading(ACTIONS.FINAL_SUBMIT, false);
        }
        if (draftStatus) {
          this.setSuccess(ACTIONS.DRAFT_SUBMIT, true);
          this.setIsLoading(ACTIONS.DRAFT_SUBMIT, false);
        }
        if (characterSubmitStatus) {
          this.setSuccess(ACTIONS.CHARACTER_SUBMIT, true);
          this.setIsLoading(ACTIONS.CHARACTER_SUBMIT, false);
        }
      })
      .catch((error) => {
        console.log("Submit character error", error.message);
        this.setError(ACTIONS.SUBMIT, error.message);
        this.setIsLoading(ACTIONS.SUBMIT, false);
      });
  }

  submitScript(data) {
    this.setIsLoading(ACTIONS.SUBMIT_SCRIPT, true);
    this.setSuccess(ACTIONS.SUBMIT_SCRIPT, false);
    this.setError(ACTIONS.SUBMIT_SCRIPT, false);

    writingCompetitionAPI
      .submitScript(data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setSuccess(ACTIONS.SUBMIT_SCRIPT, true);
          return response.json();
        }
        throw new Error(response.status);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.SUBMIT_SCRIPT, false);
        this.setSuccess(ACTIONS.SUBMIT_SCRIPT, true);
        console.log("Submit character", this[`success${ACTIONS.SUBMIT_SCRIPT}`]);
      })
      .catch((error) => {
        console.log("Submit character error", error.message);
        this.setError(ACTIONS.SUBMIT_SCRIPT, error.message);
        this.setIsLoading(ACTIONS.SUBMIT_SCRIPT, false);
      });
  }

  vote({ submissionID, onSucess }) {
    this.setIsLoading(ACTIONS.VOTE, true);
    this.setSuccess(ACTIONS.VOTE, false);
    this.setError(ACTIONS.VOTE, false);

    writingCompetitionAPI
      .vote(submissionID)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setSuccess(ACTIONS.VOTE, true);
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        this.setIsLoading(ACTIONS.VOTE, false);
        this.setSuccess(ACTIONS.VOTE, true);
        onSucess && onSucess(data.status, data.message);
        console.log("Submit character", this[`success${ACTIONS.VOTE}`]);
      })
      .catch((error) => {
        console.log("Submit character error", error.message);
        this.setError(ACTIONS.VOTE, error.message);
        this.setIsLoading(ACTIONS.VOTE, false);
      });
  }
};

export default WritingCompetition;
