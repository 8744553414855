import React, { useContext } from "react";
import cs from "classnames";
import classes from "./CharactersModal.module.scss";
import CharacterItem from "./components/CharacterItem";
import PropTypes from "prop-types";
import { StoreContext } from "../../../../../contexts";
import { observer } from "mobx-react-lite";
import { formatChildNames } from "../../WritingCompetition";

const CharactersModal = observer(() => {
  const { common } = useContext(StoreContext);

  const characterData = common.get("currentCharacter");

  const childNames = formatChildNames(characterData?.selected_childs);

  console.log(characterData?.character_submission?.data?.setting, "characterDatacharacterData")

  return (
    <div className={cs(classes.characterFormModal)}>
      <div className={cs(classes.headerCharacter)}>
        <h2 className={cs(classes.heading, classes.grandstanderFamily)}>Writers:</h2>
        <h2 className={cs(classes.heading, classes.grandstanderFamily, classes.red)}>{childNames}</h2>
        <p className={cs(classes.red)}>
          From {characterData?.location?.address && `${characterData.location.address} `}
          {/* {characterData?.location?.state && `, ${characterData.location.state}, `} */}
          {/* {characterData?.location?.country} */}
        </p>
      </div>

      <div className={cs(classes.bodyCharacter)}>
        <h2 className={cs(classes.heading, classes.grandstanderFamily)}>Charecters</h2>

        <div className={cs(classes.characterItemsContainer)}>
          {characterData?.character_submission?.data?.characters.map((character, i) => (
            <CharacterItem key={i} character={character} />
          ))}
          <div></div>
        </div>
      </div>
    </div>
  );
});

CharactersModal.propTypes = {
  characterData: PropTypes.object,
};

export default CharactersModal;
