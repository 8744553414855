import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import classes from "./Popup.module.scss";
import Icon from "../Icon";

const Popup = ({ popup, children, cardStyles }) => {
  const onClickOverlay = (event) => {
    if (event.target.matches(`[data-name="modal-overlay"]`)) popup.onClose();
  };

  return (
    <div data-name="modal-overlay" className={cs(classes.overlay)} role="button" onClick={onClickOverlay}>
      <div className={cs(classes.card)} style={cardStyles}>
        <div
          onClick={() => {
            popup.onClose();
          }}
          className={classes.closeContainer}
        >
          <Icon size="xs" name="x" cursorPointer={true} />
        </div>
        {children}
      </div>
    </div>
  );
};

Popup.propTypes = {
  popup: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  cardStyles: PropTypes.object,
};

Popup.defaultProps = {};

export default Popup;
