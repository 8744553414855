import Anchor from "../../../assets/svg/anchor.svg";
import ArrowDropdown from "../../../assets/svg/arrow-dropdown.svg";
import ArrowDropup from "../../../assets/svg/arrow-dropup.svg";
import ArrowUp from "../../../assets/svg/arrow-up.svg";
import ArrowDown from "../../../assets/svg/arrow-down.svg";
import ArrowInCircle from "../../../assets/svg/arrow-back-in-circle.svg";
import ArrowPointer from "../../../assets/svg/arrow-pointer.svg";
import Badge from "../../../assets/svg/badge.svg";
import Bell from "../../../assets/svg/bell.svg";
import BookAndMagic from "../../../assets/svg/book-and-magic.svg";
import BookAndMagnifier from "../../../assets/svg/book-and-magnifier.svg";
import BracketLeft from "../../../assets/svg/bracket-left.svg";
import BracketRight from "../../../assets/svg/bracket-right.svg";
import Cake from "../../../assets/svg/cake.svg";
import CanvasWithLandscape from "../../../assets/svg/canvas-with-landscape.svg";
import CheckMark from "../../../assets/svg/check-mark.svg";
import CheckedCircleOrange from "../../../assets/svg/checked-circle-orange.svg";
import CheckedCircleWhite from "../../../assets/svg/checked-circle-white.svg";
import ChevronDownDouble from "../../../assets/svg/chevron-down-double.svg";
import ChevronLeft from "../../../assets/svg/chevron-left.svg";
import ChevronLeftDouble from "../../../assets/svg/chevron-left-double.svg";
import ChevronRight from "../../../assets/svg/chevron-right.svg";
import ChevronRightDouble from "../../../assets/svg/chevron-right-double.svg";
import ChevronUpDouble from "../../../assets/svg/chevron-up-double.svg";
import Clapperboard from "../../../assets/svg/clapperboard.svg";
import Clock from "../../../assets/svg/clock.svg";
import Cloud from "../../../assets/svg/cloud.svg";
import CreditCard from "../../../assets/svg/credit-card.svg";
import Celeberation from "../../../assets/svg/celebration.svg";
import DiagramWithStar from "../../../assets/svg/diagram-with-star.svg";
import Dialog from "../../../assets/svg/dialog.svg";
import DollarCoin from "../../../assets/svg/dollar-coin.svg";
import Donation from "../../../assets/svg/donation.svg";
import Earth from "../../../assets/svg/earth.svg";
import Edit from "../../../assets/svg/edit.svg";
import Exit from "../../../assets/svg/exit.svg";
import EyeClosed from "../../../assets/svg/eye-closed.svg";
import EyeOpened from "../../../assets/svg/eye-opened.svg";
import Flag2 from "../../../assets/svg/flag-2.svg";
import Flame from "../../../assets/svg/flame.svg";
import FullGiftParty from "../../../assets/svg/full-gift-party.svg";
import Hamburger from "../../../assets/svg/hamburger.svg";
import HandWithCoin from "../../../assets/svg/hand-with-coin.svg";
import HandWithCreditCard from "../../../assets/svg/hand-with-credit-card.svg";
import HandWithIncreaseArrow from "../../../assets/svg/hand-with-increase-arrow.svg";
import Information from "../../../assets/svg/information.svg";
import Letter from "../../../assets/svg/letter.svg";
import Lightbulb from "../../../assets/svg/lightbulb.svg";
import Logout from "../../../assets/svg/logout.svg";
import MapMarker from "../../../assets/svg/map-marker.svg";
import Package from "../../../assets/svg/package.svg";
import PartnersMembershipBasic from "../../../assets/svg/level-basic.svg";
import PartnersMembershipGold from "../../../assets/svg/level-gold.svg";
import PartnersMembershipPlatinum from "../../../assets/svg/level-platinum.svg";
import PartnersMembershipSilver from "../../../assets/svg/level-silver.svg";
import PersonalPoster from "../../../assets/svg/personal-poster.svg";
import People from "../../../assets/svg/people.svg";
import People2 from "../../../assets/svg/people-2.svg";
import People3 from "../../../assets/svg/people-3.svg";
import PersonAndMusicalNote from "../../../assets/svg/person-and-musical-note.svg";
import Phone from "../../../assets/svg/phone.svg";
import Play from "../../../assets/svg/play.svg";
import PlayVideo from "../../../assets/svg/play-video.svg";
import PlayerCircle from "../../../assets/svg/player-circle.svg";
import PlayerPlay from "../../../assets/svg/player-play.svg";
import Plus from "../../../assets/svg/plus.svg";
import Share from "../../../assets/svg/share.svg";
import ShareMarron from "../../../assets/svg/share-maroon.svg";
import QuestionMarkInCircle from "../../../assets/svg/question-mark-in-circle.svg";
import Speaker from "../../../assets/svg/speaker.svg";
import Star from "../../../assets/svg/star.svg";
import TextSymbol from "../../../assets/svg/text-symbol.svg";
import Timer from "../../../assets/svg/timer.svg";
import TriangleEmpty from "../../../assets/svg/triangle-empty.svg";
import Tour from "../../../assets/svg/tour.svg";
import User from "../../../assets/svg/user.svg";
import Users from "../../../assets/svg/users.svg";
import VideoCamera from "../../../assets/svg/video-camera.svg";
import VideoTape from "../../../assets/svg/video-tape.svg";
import WhatsApp from "../../../assets/svg/whatsapp.svg";
import X from "../../../assets/svg/x.svg";
import Search from "../../../assets/svg/search.svg";
import WatchListButton from "../../../assets/svg/watchlist-button.svg";
import PDF from "../../../assets/svg/pdf.svg";
import Playlist from "../../../assets/svg/playlist.svg";
import Calendar from "../../../assets/svg/calendar.svg";
import YouTubeWhite from "../../../assets/svg/youtube-white.svg";
import ColorPallate from "../../../assets/svg/coloring-pages-white.svg";
import PlayWhite from "../../../assets/svg/play-white.svg";
import Winners from "../../../assets/svg/winners.svg";
import PlayOrange from "../../../assets/svg/Playlist-orange.svg";
import PlayBlue from "../../../assets/svg/Playlist-blue.svg";
import Coloring from "../../../assets/svg/Coloring.svg";
import ColoringBlue from "../../../assets/svg/Coloring-blue.svg";
import Audio from "../../../assets/svg/Audio.svg";
import Answers from "../../../assets/svg/Answers.svg";
import BlueWinners from "../../../assets/svg/winners-blue.svg";
import BlueAnswers from "../../../assets/svg/answers-blue.svg";
import DeliveryTruck from "../../../assets/svg/delivery-truck.svg";
import LeftArrow from "../../../assets/svg/left-arrow.svg";
import RightArrow from "../../../assets/svg/right-arrow.svg";

import TrashIcon from "../../../assets/svg/trash-icon.svg";
import LibraryIcon from "../../../assets/svg/library.svg";
import PlayIcon from "../../../assets/svg/PlayButton.svg";
import CloudStory from "../../../assets/svg/cloudstory.svg";
import Bulb from "../../../assets/svg/bulb.svg";
import Chat from "../../../assets/svg/chat.svg";
import MaorStory from "../../../assets/svg/maorstory.svg";
import ChildStory from "../../../assets/svg/childstory.svg";
import Increase from "../../../assets/svg/increase.svg";
import Hand from "../../../assets/svg/hand.svg";
import Gift from "../../../assets/svg/gift.svg";
import HomeCake from "../../../assets/svg/cake-6.svg";
import PinkHorn from "../../../assets/svg/pinkHorn.svg";
import YellowHorn from "../../../assets/svg/yellowHorn.svg";
import PinkSearch from "../../../assets/svg/pinkSearch.svg";

import ArrowLeft from "../../../assets/svg/arrow-left-c.svg";
import ArrowRight from "../../../assets/svg/arrow-right-c.svg";
import YellowPlay from "../../../assets/svg/yellowPlay.svg";
import PinkPlay from "../../../assets/svg/pinkPlayer.svg";

import Igg from "../../../assets/svg/igg.svg";
import Hebrew from "../../../assets/svg/Hebrew.svg";
import Hassidout from "../../../assets/svg/hassidout.svg";
import Beis from "../../../assets/svg/beis.svg";
import NewsLetter from "../../../assets/svg/newsletter.svg";
import Col from "../../../assets/svg/col.svg";
import Anash from "../../../assets/svg/anash.svg";
import Chabad from "../../../assets/svg/chabad.svg";
import GreatParade from "../../../assets/svg/GreatParade.svg";
import Question from "../../../assets/svg/question.svg";
import CakeIcon from "../../../assets/svg/cake-icon.svg";
import VideoIcon from "../../../assets/svg/video.svg";
import Headphone from "../../../assets/svg/headphone.svg";
import PurpleCalendar from "../../../assets/svg/purple-calendar.svg";
import Profile from "../../../assets/svg/profile.svg";
import PinkArrow from "../../../assets/svg/pink-arrow.svg";
import Cross from "../../../assets/svg/cross.svg";
import AutoRenewal from "../../../assets/svg/auto-renewal-icon.svg";
import NewCake from "../../../assets/svg/new-cake.svg";
import UserProfile from "../../../assets/svg/user-profile-icon.svg";
import BirthdayCalendar from "../../../assets/svg/calendar-birthday.svg";
import ComicTooltip from "../../../assets/svg/comic-tooltip.svg";
import MessageTooltip from "../../../assets/svg/message-tooltip.svg";
import CrossButton from "../../../assets/svg/cross-button.svg";
import MovieIcon from "../../../assets/svg/movie.svg";
import BirthdayCelebration from "../../../assets/svg/birthday-celebrations.svg";
import ColoringIcon from "../../../assets/svg/coloring-icon.svg";
import HeadphoneIcon from "../../../assets/svg/headphone-audio.svg";
import VideoLibrary from "../../../assets/svg/video-library.svg";
import ShareArrow from "../../../assets/svg/share-arrow.svg";
import PdfBlack from "../../../assets/svg/pdf-icon.svg";
import NewPlayIcon from "../../../assets/svg/new-player.svg";
import SearchWhite from "../../../assets/svg/search-white.svg";
import CrossBig from "../../../assets/svg/cross-icon.svg";
import PlayBlackIcon from "../../../assets/svg/black-play.svg";
import CarouselArrow from "../../../assets/svg/carousel-arrow.svg";
import AddPlaylist from "../../../assets/svg/add-playlist.svg";
import GreenCross from "../../../assets/svg/playlist-cancel.svg";
import Printer from "../../../assets/svg/printer.svg";
import WhitePlay from "../../../assets/svg/white-play.svg";
import AudioIcon from "../../../assets/svg/audio-icon.svg";
import WinnerChild from "../../../assets/svg/winner-child.svg";
import WinnerPrize from "../../../assets/svg/winner-prize.svg";
import PlaylistIcon from "../../../assets/svg/add-to-playlist.svg";
import PlaylistIconWhite from "../../../assets/svg/playlist-icon-white.svg";
import PlaylistIconGreen from "../../../assets/svg/add-to-playlist-green.svg";
import PlaylistIconPink from "../../../assets/svg/add-to-playlist-pink.svg";
import PlaylistIconOrange from "../../../assets/svg/add-to-playlist-orange.svg";
import PlaylistIconBlue from "../../../assets/svg/add-to-playlist-blue.svg";
import PlaylistIconYellow from "../../../assets/svg/add-to-playlist-yellow.svg";
import PlaylistIconPurple from "../../../assets/svg/add-to-playlist-purple.svg";
import PartnersDiscount from "../../../assets/svg/partners-discount.svg";
import Tick from "../../../assets/svg/tick.svg";
import OrangeTick from "../../../assets/svg/orange-tick.svg";
import BrownTick from "../../../assets/svg/brown-tick.svg";
import PartnersIcon from "../../../assets/svg/partner-icon.svg";
import MinusIcon from "../../../assets/svg/minus.svg";
import WhiteTick from "../../../assets/svg/white-tick.svg";
import Riboon from "../../../assets/svg/ribbon.svg";

const COLORS = {
  DEFAULT: "default",
  GREY: "grey",
  WHITE: "white",
  PRIMARY: "primary",
  BROWN: "brown",
};

const NAMES = {
  Riboon: "ribbon",
  MinusIcon: "minus-icon",
  WhiteTick: "white-tick",
  OrangeTick: "orange-tick",
  BrownTick: "brown-tick",
  PartnersIcon: "partners-icon",
  Tick: "tick",
  PartnersDiscount: "PartnersDiscount",
  PlaylistIconGreen: "green-playlist",
  PlaylistIconPink: "pink-playlist",
  PlaylistIconOrange: "orange-playlist",
  PlaylistIconBlue: "blue-playlist",
  PlaylistIconYellow: "yellow-playlist",
  PlaylistIconPurple: "purple-playlist",

  PlaylistIconWhite: "playlist-icon-white",
  PlaylistIcon: "playlist-icon",
  WinnerChild: "winner-child",
  WinnerPrize: "winner-prize",
  AudioIcon: "audio-icon",
  WhitePlay: "white-play",
  Printer: "printer",
  GreenCross: "green-cross",
  AddPlaylist: "add-playlist",
  CarouselArrow: "carousel-arrow",
  PlayBlackIcon: "play-black-icon",
  CrossBig: "cross-big",
  SearchWhite: "search-white",
  NewPlayIcon: "new-play-icon",
  ShareArrow: "share-arrow",
  PdfBlack: "pdf-black",
  BirthdayCelebration: "birthday-celebration",
  ColoringIcon: "coloring-icon",
  HeadphoneIcon: "headphone-icon",
  VideoLibrary: "video-library",
  MovieIcon: "movie",
  CrossButton: "cross-button",
  ComicTooltip: "comic-tooltip",
  MessageTooltip: "message-tooltip",
  BirthdayCalendar: "birthday-calendar",
  UserProfile: "user-profile",
  NewCake: "new-cake",
  AutoRenewal: "auto-renewal",
  Profile: "profile",
  Cross: "cross",
  PinkArrow: "pink-arrow",
  PurpleCalendar: "purple-calender",
  YellowPlay: "yellow-play",
  PinkPlay: "pink-play",
  PinkHorn: "pink-horn",
  YellowHorn: "yellow-horn",
  PinkSearch: "pink-search",
  Igg: "igg",
  Hebrew: "hebrew",
  Hassidout: " hassidout",
  Beis: "beis",
  NewsLetter: "news-letter",
  Col: "col",
  Anash: "anash",
  Chabad: "chabad",
  GreatParade: "great-parade",
  Hand: "hand",
  Gift: "gift",
  HomeCake: "home-cake",
  CloudStory: "cloud-story",
  Bulb: "bulb",
  Chat: "chat",
  MaorStory: "maor-story",
  ChildStory: "child-story",
  Increase: "increase",
  PlayIcon: "play-icon",
  Headphone: "headphone",
  VideoIcon: "video-icon",
  CakeIcon: "cake-icon",
  Question: "question",
  YouTubeWhite: "youtube-white",
  ColorPallate: "color-pallate",
  PlayWhite: "play-white",
  Winners: "winners",
  PLAY_ORANGE: "play-orange",
  PLAY_BLUE: "play-blue",
  Coloring: "coloring",
  ColoringBlue: "coloring-blue",
  Audio: "audio",
  Answers: "answers",
  BlueWinners: "bluewinners",
  BlueAnswers: "blueanswers",
  DeliveryTruck: "deliverytruck",
  LeftArrow: "leftarrow",
  RightArrow: "rightarrow",
  TrashIcon: "trashicon",
  LibraryIcon: "libraryicon",
  ANCHOR: "anchor",
  ARROW_DROPDOWN: "arrow-dropdown",
  ARROW_DROPUP: "arrow-dropup",
  ARROW_UP: "arrow-up",
  ARROW_DOWN: "arrow-down",
  ARROW_IN_CIRCLE: "arrow-in-circle",
  ARROW_POINTER: "arrow-pointer",
  BADGE: "badge",
  BELL: "bell",
  BOOK_AND_MAGIC: "book-and-magic",
  BOOK_AND_MAGNIFIER: "book-and-magnifier",
  BRACKET_LEFT: "bracket-left",
  BRACKET_RIGHT: "bracket-right",
  CAKE: "cake",
  CANVAS_WITH_LANDSCAPE: "canvas-with-landscape",
  CHECK_MARK: "check-mark",
  CHECKED_CIRCLE_ORANGE: "checked-circle-orange",
  CHECKED_CIRCLE_WHITE: "checked-circle-white",
  CHEVRON_DOWN_DOUBLE: "chevron-down-double",
  CHEVRON_LEFT: "chevron-left",
  CHEVRON_LEFT_DOUBLE: "chevron-left-double",
  CHEVRON_RIGHT: "chevron-right",
  CHEVRON_RIGHT_DOUBLE: "chevron-right-double",
  CHEVRON_UP_DOUBLE: "chevron-up-double",
  CLAPPERBOARD: "clapperboard",
  CELEBRATION: "celebration",
  CLOCK: "clock",
  CLOUD: "cloud",
  CREDIT_CARD: "credit-card",
  PDF: "pdf",
  PLAYLIST: "playlist",
  DIAGRAM_WITH_STAR: "diagram-with-star",
  DIALOG: "dialog",
  DOLLAR_COIN: "dollar-coin",
  DONATION: "donation",
  EARTH: "earth",
  EDIT: "edit",
  EXIT: "exit",
  EYE_CLOSED: "eye-closed",
  EYE_OPENED: "eye-opened",
  FLAG_2: "flag-2",
  FLAME: "flame",
  FULL_GIFT_PARTY: "full-gift-party",
  HAMBURGER: "hamburger",
  HAND_WITH_COIN: "hand-with-coin",
  HAND_WITH_CREDIT_CARD: "hand-with-credit-card",
  HAND_WITH_INCREASE_ARROW: "hand-with-increase-arrow",
  INFORMATION: "information",
  LETTER: "letter",
  LIGHTBULB: "lightbulb",
  LOGOUT: "logout",
  MAP_MARKER: "map-marker",
  PACKAGE: "package",
  PARTNERS_MEMBERSHIP_BASIC: "partners-membership-basic",
  PARTNERS_MEMBERSHIP_GOLD: "partners-membership-gold",
  PARTNERS_MEMBERSHIP_PLATINUM: "partners-membership-platinum",
  PARTNERS_MEMBERSHIP_SILVER: "partners-membership-silver",
  PEOPLE: "people",
  PEOPLE_2: "people-2",
  PEOPLE_3: "people-3",
  PERSON_AND_MUSICAL_NOTE: "person-and-musical-note",
  PHONE: "phone",
  PLAY: "play",
  PLAY_VIDEO: "play-video",
  PLAYER_CIRCLE: "player-circle",
  PLAYER_PLAY: "player-play",
  PLUS: "plus",
  SHARE: "share",
  ShareMarron: "share-maroon",
  QUESTION_MARK_IN_CIRCLE: "question-mark-in-circle",
  SPEAKER: "speaker",
  STAR: "star",
  TEXT_SYMBOL: "text-symbol",
  TIMER: "timer",
  TOUR: "tour",
  TRIANGLE_EMPTY: "triangle-empty",
  USER: "user",
  USERS: "users",
  VIDEO_CAMERA: "video-camera",
  VIDEO_TAPE: "video-tape",
  WHATSAPP: "whatsapp",
  X: "x",
  SEARCH: "search",
  WATCHLIST_BUTTON: "watchlist-button",
  PERSONAL_POSTER: "personal-poster",
  CALENDAR: "calendar",
  ArrowLeft: "arrowleft",
  ArrowRight: "arrowright",
};

const SIZES = {
  STRETCHED: "stretched",
  XXS: "xxs",
  XS: "xs",
  S: "s",
  M: "m",
  L: "l",
  XL: "xl",
  XXL: "xxl",
  XXXL: "xxxl",
  AXSS: "axss",
};

const getIcon = (value) => {
  switch (value) {
    case NAMES.Riboon:
      return Riboon;
    case NAMES.WhiteTick:
      return WhiteTick;
    case NAMES.MinusIcon:
      return MinusIcon;
    case NAMES.OrangeTick:
      return OrangeTick;
    case NAMES.BrownTick:
      return BrownTick;
    case NAMES.PartnersIcon:
      return PartnersIcon;
    case NAMES.Tick:
      return Tick;
    case NAMES.PartnersDiscount:
      return PartnersDiscount;
    case NAMES.PlaylistIconBlue:
      return PlaylistIconBlue;
    case NAMES.PlaylistIconGreen:
      return PlaylistIconGreen;
    case NAMES.PlaylistIconPink:
      return PlaylistIconPink;
    case NAMES.PlaylistIconYellow:
      return PlaylistIconYellow;
    case NAMES.PlaylistIconOrange:
      return PlaylistIconOrange;
    case NAMES.PlaylistIconPurple:
      return PlaylistIconPurple;
    case NAMES.PlaylistIconWhite:
      return PlaylistIconWhite;
    case NAMES.PlaylistIcon:
      return PlaylistIcon;
    case NAMES.WinnerChild:
      return WinnerChild;
    case NAMES.WinnerPrize:
      return WinnerPrize;
    case NAMES.AudioIcon:
      return AudioIcon;
    case NAMES.WhitePlay:
      return WhitePlay;
    case NAMES.Printer:
      return Printer;
    case NAMES.GreenCross:
      return GreenCross;
    case NAMES.AddPlaylist:
      return AddPlaylist;
    case NAMES.CarouselArrow:
      return CarouselArrow;
    case NAMES.PlayBlackIcon:
      return PlayBlackIcon;
    case NAMES.CrossBig:
      return CrossBig;
    case NAMES.SearchWhite:
      return SearchWhite;
    case NAMES.NewPlayIcon:
      return NewPlayIcon;
    case NAMES.ShareArrow:
      return ShareArrow;
    case NAMES.PdfBlack:
      return PdfBlack;
    case NAMES.BirthdayCelebration:
      return BirthdayCelebration;
    case NAMES.HeadphoneIcon:
      return HeadphoneIcon;
    case NAMES.ColoringIcon:
      return ColoringIcon;
    case NAMES.VideoLibrary:
      return VideoLibrary;
    case NAMES.MovieIcon:
      return MovieIcon;
    case NAMES.CrossButton:
      return CrossButton;
    case NAMES.MessageTooltip:
      return MessageTooltip;
    case NAMES.ComicTooltip:
      return ComicTooltip;
    case NAMES.Cross:
      return Cross;
    case NAMES.PinkArrow:
      return PinkArrow;
    case NAMES.BirthdayCalendar:
      return BirthdayCalendar;
    case NAMES.UserProfile:
      return UserProfile;
    case NAMES.NewCake:
      return NewCake;
    case NAMES.AutoRenewal:
      return AutoRenewal;
    case NAMES.Profile:
      return Profile;
    case NAMES.PurpleCalendar:
      return PurpleCalendar;
    case NAMES.ShareMarron:
      return ShareMarron;
    case NAMES.PinkPlay:
      return PinkPlay;
    case NAMES.YellowPlay:
      return YellowPlay;
    case NAMES.YellowHorn:
      return YellowHorn;
    case NAMES.PinkHorn:
      return PinkHorn;
    case NAMES.PinkSearch:
      return PinkSearch;
    case NAMES.Igg:
      return Igg;
    case NAMES.Hebrew:
      return Hebrew;
    case NAMES.Hassidout:
      return Hassidout;
    case NAMES.Beis:
      return Beis;
    case NAMES.NewsLetter:
      return NewsLetter;
    case NAMES.Col:
      return Col;
    case NAMES.Anash:
      return Anash;
    case NAMES.Chabad:
      return Chabad;
    case NAMES.GreatParade:
      return GreatParade;
    case NAMES.HomeCake:
      return HomeCake;
    case NAMES.Hand:
      return Hand;
    case NAMES.Gift:
      return Gift;
    case NAMES.ChildStory:
      return ChildStory;
    case NAMES.MaorStory:
      return MaorStory;
    case NAMES.Bulb:
      return Bulb;
    case NAMES.Increase:
      return Increase;
    case NAMES.CloudStory:
      return CloudStory;
    case NAMES.Chat:
      return Chat;

    case NAMES.PlayIcon:
      return PlayIcon;
    case NAMES.Headphone:
      return Headphone;
    case NAMES.VideoIcon:
      return VideoIcon;
    case NAMES.CakeIcon:
      return CakeIcon;
    case NAMES.Question:
      return Question;
    case NAMES.YouTubeWhite:
      return YouTubeWhite;
    case NAMES.ColorPallate:
      return ColorPallate;
    case NAMES.PlayWhite:
      return PlayWhite;
    case NAMES.Winners:
      return Winners;
    case NAMES.PLAY_ORANGE:
      return PlayOrange;
    case NAMES.PLAY_BLUE:
      return PlayBlue;
    case NAMES.Coloring:
      return Coloring;
    case NAMES.ColoringBlue:
      return ColoringBlue;
    case NAMES.Audio:
      return Audio;
    case NAMES.Answers:
      return Answers;
    case NAMES.BlueWinners:
      return BlueWinners;
    case NAMES.BlueAnswers:
      return BlueAnswers;
    case NAMES.DeliveryTruck:
      return DeliveryTruck;
    case NAMES.LeftArrow:
      return LeftArrow;
    case NAMES.RightArrow:
      return RightArrow;
    case NAMES.TrashIcon:
      return TrashIcon;
    case NAMES.LibraryIcon:
      return LibraryIcon;
    case NAMES.ANCHOR:
      return Anchor;
    case NAMES.ARROW_DROPDOWN:
      return ArrowDropdown;
    case NAMES.ARROW_DROPUP:
      return ArrowDropup;
    case NAMES.ARROW_UP:
      return ArrowUp;
    case NAMES.ARROW_DOWN:
      return ArrowDown;
    case NAMES.ARROW_IN_CIRCLE:
      return ArrowInCircle;
    case NAMES.ARROW_POINTER:
      return ArrowPointer;
    case NAMES.BADGE:
      return Badge;
    case NAMES.BELL:
      return Bell;
    case NAMES.BOOK_AND_MAGIC:
      return BookAndMagic;
    case NAMES.BOOK_AND_MAGNIFIER:
      return BookAndMagnifier;
    case NAMES.BRACKET_LEFT:
      return BracketLeft;
    case NAMES.BRACKET_RIGHT:
      return BracketRight;
    case NAMES.CAKE:
      return Cake;
    case NAMES.CANVAS_WITH_LANDSCAPE:
      return CanvasWithLandscape;
    case NAMES.CHECK_MARK:
      return CheckMark;
    case NAMES.CHECKED_CIRCLE_ORANGE:
      return CheckedCircleOrange;
    case NAMES.CHECKED_CIRCLE_WHITE:
      return CheckedCircleWhite;
    case NAMES.CHEVRON_DOWN_DOUBLE:
      return ChevronDownDouble;
    case NAMES.CHEVRON_LEFT:
      return ChevronLeft;
    case NAMES.CHEVRON_LEFT_DOUBLE:
      return ChevronLeftDouble;
    case NAMES.CHEVRON_RIGHT:
      return ChevronRight;
    case NAMES.CHEVRON_RIGHT_DOUBLE:
      return ChevronRightDouble;
    case NAMES.CHEVRON_UP_DOUBLE:
      return ChevronUpDouble;
    case NAMES.CLAPPERBOARD:
      return Clapperboard;
    case NAMES.CLOCK:
      return Clock;
    case NAMES.CLOUD:
      return Cloud;
    case NAMES.CREDIT_CARD:
      return CreditCard;
    case NAMES.DIAGRAM_WITH_STAR:
      return DiagramWithStar;
    case NAMES.DIALOG:
      return Dialog;
    case NAMES.DOLLAR_COIN:
      return DollarCoin;
    case NAMES.DONATION:
      return Donation;
    case NAMES.EARTH:
      return Earth;
    case NAMES.EDIT:
      return Edit;
    case NAMES.EXIT:
      return Exit;
    case NAMES.EYE_CLOSED:
      return EyeClosed;
    case NAMES.EYE_OPENED:
      return EyeOpened;
    case NAMES.FLAG_2:
      return Flag2;
    case NAMES.FLAME:
      return Flame;
    case NAMES.HAMBURGER:
      return Hamburger;
    case NAMES.HAND_WITH_COIN:
      return HandWithCoin;
    case NAMES.HAND_WITH_CREDIT_CARD:
      return HandWithCreditCard;
    case NAMES.HAND_WITH_INCREASE_ARROW:
      return HandWithIncreaseArrow;
    case NAMES.INFORMATION:
      return Information;
    case NAMES.LETTER:
      return Letter;
    case NAMES.LIGHTBULB:
      return Lightbulb;
    case NAMES.LOGOUT:
      return Logout;
    case NAMES.MAP_MARKER:
      return MapMarker;
    case NAMES.PACKAGE:
      return Package;
    case NAMES.PARTNERS_MEMBERSHIP_BASIC:
      return PartnersMembershipBasic;
    case NAMES.PARTNERS_MEMBERSHIP_GOLD:
      return PartnersMembershipGold;
    case NAMES.PARTNERS_MEMBERSHIP_PLATINUM:
      return PartnersMembershipPlatinum;
    case NAMES.PARTNERS_MEMBERSHIP_SILVER:
      return PartnersMembershipSilver;
    case NAMES.PERSONAL_POSTER:
      return PersonalPoster;
    case NAMES.PHONE:
      return Phone;
    case NAMES.PEOPLE:
      return People;
    case NAMES.PEOPLE_2:
      return People2;
    case NAMES.PEOPLE_3:
      return People3;
    case NAMES.PERSON_AND_MUSICAL_NOTE:
      return PersonAndMusicalNote;
    case NAMES.PLAY:
      return Play;
    case NAMES.PLAY_VIDEO:
      return PlayVideo;
    case NAMES.PLUS:
      return Plus;
    case NAMES.QUESTION_MARK_IN_CIRCLE:
      return QuestionMarkInCircle;
    case NAMES.SPEAKER:
      return Speaker;
    case NAMES.STAR:
      return Star;
    case NAMES.TEXT_SYMBOL:
      return TextSymbol;
    case NAMES.TIMER:
      return Timer;
    case NAMES.TRIANGLE_EMPTY:
      return TriangleEmpty;
    case NAMES.USER:
      return User;
    case NAMES.USERS:
      return Users;
    case NAMES.VIDEO_CAMERA:
      return VideoCamera;
    case NAMES.VIDEO_TAPE:
      return VideoTape;
    case NAMES.WHATSAPP:
      return WhatsApp;
    case NAMES.X:
      return X;
    case NAMES.SEARCH:
      return Search;
    case NAMES.PLAYER_CIRCLE:
      return PlayerCircle;
    case NAMES.PLAYER_PLAY:
      return PlayerPlay;
    case NAMES.WATCHLIST_BUTTON:
      return WatchListButton;
    case NAMES.PDF:
      return PDF;
    case NAMES.SHARE:
      return Share;
    case NAMES.PLAYLIST:
      return Playlist;
    case NAMES.TOUR:
      return Tour;
    case NAMES.CELEBRATION:
      return Celeberation;
    case NAMES.FULL_GIFT_PARTY:
      return FullGiftParty;
    case NAMES.CALENDAR:
      return Calendar;
    case NAMES.ArrowLeft:
      return ArrowLeft;
    case NAMES.ArrowRight:
      return ArrowRight;
    default:
      return null;
  }
};

export { COLORS, NAMES, SIZES, getIcon };
